export const environment = {
  production: false,
  apiUrl: 'https://zna-api-qa.zurichna.com/ext/dcit/dcit-api/api',
  subletUrl: 'https://zna-api-qa.zurichna.com/ext/dcit/dcit-api/api',
  environmentName: 'znaqa',
  isOktaEnabled: true,
  APISubscriptionKey: 'bb3715dd032e48e8967d32a3e653adbe',
  CLIENT_ID: '0oa10zdhp0lk7FshM0h8',
  ISSUER: 'https://qalogin.zurichna.com/oauth2/aus101fy9q40SE6DT0h8',
  salesforceUrl: "https://zurich--casessit.sandbox.my.salesforce.com/",
  exportButtonVisible: true
};
