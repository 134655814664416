<app-header></app-header>
<router-outlet>

</router-outlet>

<app-footer>

</app-footer>
<button style="background:#5081ff; 
                transform: rotate(45deg); 
                position: sticky; 
                position: -webkit-sticky; 
                bottom: -4px; 
                left: -3px; 
                margin-bottom: 0px; 
                z-index: 10000;
                background-color: transparent !important;
                font-family: 'Open Sans',sans-serif;
                border: none;
                position: fixed;
                z-index: 99999990; 
                outline: none;
                cursor: auto;">

    <div style="border-bottom-color: #5081ff;
                border-top-color: #5081ff;
                left: 0;
                transform: rotate(-45deg);
                border-right: 81px solid transparent;
                bottom: 0;
                border-bottom: 81px solid;
                position: absolute;
                width: 0;
                height: 0;
                z-index: -1;
                cursor: auto;
                color:#5081ff !important">
    </div>
    <div style="line-height: 108px !important;
                height: 81px;
                width: 81px;
                text-align: center;
                z-index: 1000;
                color: #ffffff;
                font-size: 14px;
                font-weight: normal !important;" class="tx-white"><span class="tx-white text-center"
            style="cursor: pointer;" (click)="openFeedback()">Feedback</span>
    </div>
</button>