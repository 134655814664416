<div class="container-fluid pd-r-0 pd-l-0">
    <ng-container>
        <div class="row" *ngFor="let job of jobList; let i = index">
            <ng-container *ngIf="!job.scsAdded">
                <div class="card col-12 pd-x-0">
                    <div class="card-header  pd-y-5">
                        <div class="row d-flex mg-l-0 justify-content-between">
                            <div class="card-option row align-items-center tx-24">
                                <a class="pd-r-10 mg-l-5"><i (click)="previewDetailsJobsTable(i)"
                                        *ngIf="!jobListShow[i]" class="ion-ios-arrow-down" id="arrowDown{{ i }}"></i>
                                    <i (click)="previewDetailsJobsTable(i)" class="ion-ios-arrow-up"
                                        *ngIf="jobListShow[i]" id="arrowUp{{ i }}"></i></a>
                                <label class="section-title mg-b-0 tx-14 mg-t-0">{{ job?.jobNumber }} - {{
                                    job?.jobComponent }}/{{
                                    job?.lossDescription
                                    }}
                                </label>
                            </div>

                            <div>
                                <button *ngIf="!disabledField"
                                    class="btn btn-oblong zurich-blue bg-white btn-sm mg-r-5 btn-zurich-border"
                                    placement="top" ngbTooltip="Edit Job" (click)="editJob(job)">
                                    <i class="fa fa-pencil"></i>
                                </button>
                                <button *ngIf="disabledField"
                                    class="btn btn-oblong zurich-blue bg-white btn-sm mg-r-5 btn-zurich-border"
                                    placement="top" ngbTooltip="Edit Job" data-toggle="modal"
                                    data-target="#updateClaim">
                                    <i class="fa fa-pencil"></i>
                                </button>
                                <button *ngIf="!disabledField" data-toggle="modal"
                                    [attr.data-target]="'#confirmActionModal'+i"
                                    class="btn btn-oblong zurich-blue bg-white btn-sm mg-r-5 btn-zurich-border pd-b-3"
                                    id="deleteJobButton" (click)="setJobDelete(i)">
                                    <i class="icon icon--trash_24_outline pd-r-10 mg-r-0"></i>
                                    Delete Job
                                </button>
                                <button *ngIf="disabledField" data-toggle="modal" data-target="#updateClaim"
                                    class="btn btn-oblong zurich-blue bg-white btn-sm mg-r-5 btn-zurich-border pd-b-3"
                                    id="deleteJobButton">
                                    <i class="icon icon--trash_24_outline pd-r-10 mg-r-0"></i>
                                    Delete Job
                                </button>
                            </div>
                        </div>
                        <app-confirm-action [header]="header" [message]="message" [buttonMessage]="buttonMessage"
                            [indexNum]="indexNum + i" (confirmActionEvent)="deleteJob($event)"></app-confirm-action>
                        <div *ngIf="jobListShow[i]" class="row">
                            <div *ngIf="job.type != 'BENEFIT'" class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <label class="form-control-label col-12 p-style" placement="top"
                                    ngbTooltip="{{job.customerComplaintDesc}}"
                                    [disableTooltip]="!job.customerComplaintDesc">COMPLAINT:
                                    {{job.customerComplaintDesc}}

                                </label>
                                <label class="form-control-label col-12 p-style" placement="top"
                                    ngbTooltip="{{job.causeDesc}}" [disableTooltip]="!job.causeDesc">CAUSE:
                                    {{job.causeDesc}}
                                </label>
                                <label class="form-control-label col-12 p-style" placement="top"
                                    ngbTooltip="{{job.correctionDesc}}"
                                    [disableTooltip]="!job.correctionDesc">CORRECTION: {{job.correctionDesc}}
                                </label>
                            </div>
                            <div *ngIf="job.type != 'BENEFIT'" class="col-xl-4  col-md-6 col-sm-12">
                                <!-- ENGINE -->
                                <label *ngIf="job.jobComponent?.includes('Engine') && job.engineCoolantAndOilStatus"
                                    class="form-control-label col-12 p-style" style="width: 450px !important;"
                                    placement="top" ngbTooltip="{{job.engineCoolantAndOilStatus}}">What is the fluid
                                    level and condition for coolant and oil? {{ job.engineCoolantAndOilStatus }}
                                </label>
                                <label *ngIf="job.jobComponent?.includes('Engine') && job.anyEngineFluidLeaks"
                                    class="form-control-label col-12 p-style" placement="top"
                                    ngbTooltip="{{job.anyEngineFluidLeaks}}">Any fluid leaks? {{job.anyEngineFluidLeaks
                                    }}
                                </label>
                                <label *ngIf="job.jobComponent?.includes('Engine') && job.engineFaultCodes"
                                    class="form-control-label col-12 p-style" placement="top"
                                    ngbTooltip="{{job.engineFaultCodes}}">Any fault codes present? {{
                                    job.engineFaultCodes }}
                                </label>
                                <label
                                    *ngIf="job.jobComponent?.includes('Engine') && job.engineTearDownToConfirmTheFailure"
                                    class="form-control-label col-12 p-style" placement="top"
                                    ngbTooltip="{{job.engineTearDownToConfirmTheFailure}}">Describe the level of tear
                                    down: {{ job.engineTearDownToConfirmTheFailure }}
                                </label>
                                <!-- TRANSMISSION -->
                                <label
                                    *ngIf="job.jobComponent?.includes('Transmission') && job.transmissionFluidAndCondition"
                                    class="form-control-label col-12 p-style" style="width: 450px !important;"
                                    placement="top" ngbTooltip="{{job.transmissionFluidAndCondition}}">What is the
                                    transmission fluid level and condition? {{job.transmissionFluidAndCondition }}
                                </label>
                                <label
                                    *ngIf="job.jobComponent?.includes('Transmission') && job.anyTransmissionFluidLeaks"
                                    class="form-control-label col-12 p-style" style="width: 450px !important;"
                                    placement="top" ngbTooltip="{{job.anyTransmissionFluidLeaks}}">Any fluid leaks?
                                    {{job.anyTransmissionFluidLeaks }}
                                </label>
                                <label *ngIf="job.jobComponent?.includes('Transmission') && job.transmissionFaultCodes"
                                    class="form-control-label col-12 p-style" style="width: 450px !important;"
                                    placement="top" ngbTooltip="{{job.transmissionFaultCodes}}">Any fault codes present?
                                    {{job.transmissionFaultCodes }}
                                </label>
                                <label *ngIf="job.jobComponent?.includes('Transmission') && job.transmissionOfferStatus"
                                    class="form-control-label col-12 p-style" style="width: 450px !important;"
                                    placement="top" style="width: 450px !important;"
                                    ngbTooltip="{{job.transmissionOfferStatus}}">Describe internal parts or repairs
                                    offered by the factory? {{ job.transmissionOfferStatus }}
                                </label>
                                <label
                                    *ngIf="job.jobComponent?.includes('Transmission') && job.transmissionTearDownToConfirmTheFailure"
                                    class="form-control-label col-12 p-style" style="width: 450px !important;"
                                    placement="top" ngbTooltip="{{job.transmissionTearDownToConfirmTheFailure}}">Has any
                                    tear down been done to confirm the failure? {{
                                    job.transmissionTearDownToConfirmTheFailure }}
                                </label>
                                <!-- TIRE & WHEEL -->
                                <label
                                    *ngIf="job.jobComponent?.includes('Wheel') && job.jobComponent?.includes('Tire') && job.treadDepth"
                                    class="form-control-label col-12 p-style" placement="top"
                                    ngbTooltip="{{job.treadDepth}}">Tread depth: {{ job.treadDepth }}
                                </label>
                            </div>
                            <div *ngIf="job.type == 'BENEFIT'" class="col-xl-4  col-md-6 col-sm-12">
                                <label class="form-control-label col-12 p-style" placement="top"
                                    ngbTooltip="{{job?.sublets[0]?.description}}"
                                    [disableTooltip]="!job?.sublets[0]?.description">Description: {{
                                    job?.sublets[0]?.description ? job?.sublets[0]?.description : "No" }}
                                </label>
                            </div>
                            <div *ngIf="job.type != 'BENEFIT' && disabledField" class="col-xl-4 col-md-6 col-sm-12"
                                style="text-align: end; align-self: end;">
                                <label class="form-control-label col-12 p-style tx-bold" placement="top"
                                    ngbTooltip="{{job.scsUpdateNote}}"
                                    [disableTooltip]="!job.scsUpdateNote">{{job.scsUpdateNote}}
                                </label>
                            </div>
                            <div *ngIf="job.type == 'BENEFIT' && disabledField" class="col-xl-8 col-md-6 col-sm-12"
                                style="text-align: end; align-self: end;">
                                <label class="form-control-label col-12 p-style tx-bold" placement="top"
                                    ngbTooltip="{{job.scsUpdateNote}}"
                                    [disableTooltip]="!job.scsUpdateNote">{{job.scsUpdateNote}}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div id="collapse{{ i }}" class="card-body pd-x-0 collapse " [ngClass]="{
          'show': jobListShow[i],
          'out': !jobListShow[i]
        }">
                        <div *ngIf="!disabledField" class="row align-self-center justify-content-end mg-r-10">
                            <button *ngIf="job.type != 'BENEFIT'" data-toggle="modal" data-target="#newPartModal"
                                class="btn btn-oblong zurich-blue bg-white btn-sm mg-r-5 btn-zurich-border"
                                id="openPartModalButton{{ i }}"
                                (click)="setJobIndex(i);  resetPartForm(false); focusPartNumber()">
                                <i class="fa fa-plus pd-r-10" style="font-size: 10px;"></i>Add Part
                            </button>
                            <button *ngIf="job.type != 'BENEFIT'" data-toggle="modal" data-target="#newLaborModal"
                                class="btn btn-oblong zurich-blue bg-white btn-sm mg-r-5 btn-zurich-border"
                                (click)="setJobIndex(i); resetLaborForm(); focusLaborDescription();setDefaultLaborRate();"
                                id="openLaborModalButton{{ i }}">
                                <i class="fa fa-plus pd-r-10" style="font-size: 10px;"></i>Add Labor
                            </button>
                            <button *ngIf="job.type != 'BENEFIT'" data-toggle="modal" data-target="#newSubletModal"
                                class="btn btn-oblong zurich-blue bg-white btn-sm mg-r-5 btn-zurich-border"
                                (click)="setJobIndex(i); resetSubletForm(false); focusSubletDescription();"
                                id="openSubletModalButton{{ i }}">
                                <i class="fa fa-plus pd-r-10" style="font-size: 10px;"></i>Add Sublet
                            </button>

                        </div>

                        <!-- DISABLED FIELD UPDATE-->
                        <div *ngIf="disabledField" class="row align-self-center justify-content-end mg-r-10">
                            <button *ngIf="job.type != 'BENEFIT'" data-toggle="modal" data-target="#updateClaim"
                                class="btn btn-oblong zurich-blue bg-white btn-sm mg-r-5 btn-zurich-border">
                                <i class="fa fa-plus pd-r-10" style="font-size: 10px;"></i>Add Part
                            </button>
                            <button *ngIf="job.type != 'BENEFIT'" data-toggle="modal" data-target="#updateClaim"
                                class="btn btn-oblong zurich-blue bg-white btn-sm mg-r-5 btn-zurich-border">
                                <i class="fa fa-plus pd-r-10" style="font-size: 10px;"></i>Add Labor
                            </button>
                            <button *ngIf="job.type != 'BENEFIT'" data-toggle="modal" data-target="#updateClaim"
                                class="btn btn-oblong zurich-blue bg-white btn-sm mg-r-5 btn-zurich-border">
                                <i class="fa fa-plus pd-r-10" style="font-size: 10px;"></i>Add Sublet
                            </button>

                        </div>

                        <div class="row mg-l-0 mg-r-0">
                            <div class="col-md pd-x-20">
                                <div class="card-body pd-x-0 pd-b-0">
                                    <div class="tab-content pd-b-0">
                                        <!-- tab-pane Customer info-->
                                        <div class="tab-pane active" id="partInformation{{ i }}">
                                            <div class="table-wrapper table-responsive">
                                                <table id="datatable{{ i }}" class="table display responsive nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center bg-white zurich-blue-table">Detail
                                                                Type</th>
                                                            <th class="text-center bg-white zurich-blue-table">Part No.
                                                            </th>
                                                            <th class="text-center bg-white zurich-blue-table"
                                                                style="max-width: 150px;">Description</th>
                                                            <th class="text-center bg-white zurich-blue-table">Unit
                                                                Price</th>
                                                            <th class="text-center bg-white zurich-blue-table">
                                                                Quantity/Labor Hours</th>
                                                            <th class="text-center bg-white zurich-blue-table">Tax</th>
                                                            <th class="text-center bg-white zurich-blue-table">Requested
                                                                Total</th>
                                                            <th *ngIf="disabledField"
                                                                class="text-center bg-white zurich-blue-table"
                                                                style="max-width: 150px;">Note</th>
                                                            <th class="bg-white zurich-blue-table"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let part of job?.parts; let iPart = index">
                                                            <ng-container *ngIf="!part.scsAdded">
                                                                <td class="text-center">Part</td>
                                                                <td class="text-center">{{ part.partNumber }}</td>
                                                                <td style="max-width: 150px;"
                                                                    class="text-center p-style-td"><label
                                                                        style="max-width: 150px;"
                                                                        class="text-center p-style-td" placement="left"
                                                                        ngbTooltip="{{part.partDescription}}">{{
                                                                        part.partDescription }}</label></td>
                                                                <td class="text-center">{{ part.unitPrice | currency:
                                                                    "$" }}</td>
                                                                <td class="text-center">{{ part.quantity }}</td>
                                                                <td class="text-center">{{ part.tax | currency: "$" }}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{
                                                                    part.requestedTotalWithTax | currency: "$"
                                                                    }}
                                                                </td>
                                                                <td *ngIf="disabledField" style="max-width: 150px;"
                                                                    class="text-center p-style-td">
                                                                    <label *ngIf="part.scsUpdateNote" placement="left"
                                                                        ngbTooltip="{{part.scsUpdateNote}}; {{part.note}}"
                                                                        [disableTooltip]="!part.scsUpdateNote">{{
                                                                        part.scsUpdateNote }}</label><label
                                                                        *ngIf="part.scsUpdateNote && part.note">;
                                                                    </label><label *ngIf="part.note"
                                                                        class="text-center"> {{ part.note }}</label>
                                                                </td>
                                                                <td *ngIf="!disabledField" class="text-center">
                                                                    <button
                                                                        class="btn btn-oblong btn-light btn-sm tx-black font-weight-bold mg-r-5"
                                                                        placement="top" ngbTooltip="Edit Part"
                                                                        data-toggle="modal" data-target="#newPartModal"
                                                                        (click)="resetPartForm(true); setJobIndex(i); editPart(part); focusPartNumber();">
                                                                        <i class="fa fa-pencil"></i>
                                                                    </button>
                                                                    <button
                                                                        class="btn btn-oblong btn-light btn-sm tx-black font-weight-bold mg-r-5 pd-b-3"
                                                                        id="'deletePartButton' + iPart"
                                                                        (click)="deletePart(part)">
                                                                        <i
                                                                            class="icon icon--trash_24_outline pd-r-10 mg-r-0"></i>

                                                                    </button>
                                                                </td>
                                                                <td *ngIf="disabledField" class="text-center">
                                                                    <button
                                                                        class="btn btn-oblong btn-light btn-sm tx-black font-weight-bold mg-r-5"
                                                                        placement="top" ngbTooltip="Edit Part"
                                                                        data-toggle="modal" data-target="#updateClaim">
                                                                        <i class="fa fa-pencil"></i>
                                                                    </button>
                                                                </td>
                                                            </ng-container>
                                                        </tr>
                                                        <tr *ngFor="let labor of job?.labors; let iLabor = index">
                                                            <ng-container *ngIf="!labor.scsAdded">
                                                                <td class="text-center">Labor</td>
                                                                <td class="text-center"></td>
                                                                <td style="max-width: 150px;"
                                                                    class="text-center p-style-td"><label
                                                                        style="max-width: 150px;"
                                                                        class="text-center p-style-td" placement="left"
                                                                        ngbTooltip="{{labor.laborDescription}}">{{
                                                                        labor.laborDescription }}</label></td>
                                                                <td class="text-center">
                                                                    {{ labor.laborBillingRate | currency: "$" }}
                                                                </td>
                                                                <td class="text-center">{{ labor.laborHours }}</td>
                                                                <td class="text-center">{{ labor.tax | currency: "$" }}
                                                                </td>

                                                                <td class="text-center">
                                                                    {{
                                                                    labor.requestedTotalWithTax
                                                                    | currency: "$"
                                                                    }}
                                                                </td>
                                                                <td *ngIf="disabledField" style="max-width: 150px;"
                                                                    class="text-center p-style-td">
                                                                    <label *ngIf="labor.scsUpdateNote" placement="left"
                                                                        ngbTooltip="{{labor.scsUpdateNote}} {{labor.note}}"
                                                                        [disableTooltip]="!labor.scsUpdateNote">{{
                                                                        labor.scsUpdateNote }} </label><label
                                                                        *ngIf="labor.scsUpdateNote && labor.note">;
                                                                    </label><label *ngIf="labor.note"
                                                                        class="text-center"> {{ labor.note }}</label>
                                                                </td>
                                                                <td *ngIf="!disabledField" class="text-center">
                                                                    <button data-toggle="modal"
                                                                        data-target="#newLaborModal"
                                                                        class="btn btn-oblong btn-light btn-sm tx-black font-weight-bold mg-r-5"
                                                                        placement="top" ngbTooltip="Edit Labor"
                                                                        (click)=" resetLaborForm(true); setJobIndex(i); editLabor(labor); focusLaborDescription();">
                                                                        <i class="fa fa-pencil"></i>
                                                                    </button>
                                                                    <button
                                                                        class="btn btn-oblong btn-light btn-sm tx-black font-weight-bold mg-r-5 pd-b-3"
                                                                        id="'deleteLaborButton' + iLabor"
                                                                        (click)="deleteLabor(labor)">
                                                                        <i
                                                                            class="icon icon--trash_24_outline pd-r-10 mg-r-0"></i>

                                                                    </button>
                                                                </td>
                                                                <td *ngIf="disabledField" class="text-center">
                                                                    <button data-toggle="modal"
                                                                        data-target="#updateClaim"
                                                                        class="btn btn-oblong btn-light btn-sm tx-black font-weight-bold mg-r-5"
                                                                        placement="top" ngbTooltip="Edit Labor">
                                                                        <i class="fa fa-pencil"></i>
                                                                    </button>
                                                                </td>
                                                            </ng-container>
                                                        </tr>
                                                        <tr *ngFor="let sublet of job?.sublets; let iSublet = index">
                                                            <ng-container *ngIf="!sublet.scsAdded">
                                                                <td class="text-center">{{ sublet.type == "S" ? "Sublet"
                                                                    : "Other" }}</td>
                                                                <td class="text-center"></td>

                                                                <td style="max-width: 150px;"
                                                                    *ngIf="sublet.description && job.type != 'BENEFIT'"
                                                                    class="text-center p-style-td"><label
                                                                        triggers="click"
                                                                        tooltipClass="my-custom-class-inner-td"
                                                                        style="max-width: 150px;"
                                                                        class="text-center p-style-td" placement="left"
                                                                        [disableTooltip]="!job?.sublets[0]?.description"
                                                                        [ngbTooltip]="sublet.description">{{
                                                                        sublet.description }}</label></td>
                                                                <td style="max-width: 150px;"
                                                                    *ngIf="job.type == 'BENEFIT' || !sublet.description"
                                                                    class="text-center p-style-td"><label
                                                                        triggers="click"
                                                                        tooltipClass="my-custom-class-inner-td"
                                                                        style="max-width: 150px;"
                                                                        class="text-center p-style-td" placement="left"
                                                                        [disableTooltip]="!job?.lossDescription"
                                                                        [ngbTooltip]="job?.lossDescription">{{
                                                                        job?.lossDescription }}</label></td>

                                                                <td class="text-center">
                                                                    {{ sublet.unitPrice | currency: "$" }}
                                                                </td>
                                                                <td class="text-center">{{ sublet.quantity ?
                                                                    sublet.quantity : "" }}</td>
                                                                <td class="text-center">{{ 0 | currency:"$" }}</td>
                                                                <td class="text-center">
                                                                    {{
                                                                    sublet.requestedTotalWithTax
                                                                    | currency: "$"
                                                                    }}

                                                                </td>
                                                                <td *ngIf="disabledField" style="max-width: 150px;"
                                                                    class="text-center p-style-td">
                                                                    <label *ngIf="sublet.scsUpdateNote" placement="left"
                                                                        ngbTooltip="{{sublet.scsUpdateNote}} {{sublet.note}}"
                                                                        [disableTooltip]="!sublet.scsUpdateNote">{{
                                                                        sublet.scsUpdateNote }} </label><label
                                                                        *ngIf="sublet.scsUpdateNote && sublet.note">;
                                                                    </label><label *ngIf="sublet.note"
                                                                        class="text-center"> {{ sublet.note }}</label>
                                                                </td>
                                                                <td *ngIf="!disabledField && sublet.type == 'S'"
                                                                    class="text-center">
                                                                    <button
                                                                        class="btn btn-oblong btn-light btn-sm tx-black font-weight-bold mg-r-5"
                                                                        placement="top" ngbTooltip="Edit Sublet"
                                                                        data-toggle="modal"
                                                                        data-target="#newSubletModal"
                                                                        (click)="resetSubletForm(true); setJobIndex(i); editSublet(sublet); focusSubletDescription()">
                                                                        <i class="fa fa-pencil"></i>
                                                                    </button>
                                                                    <button
                                                                        class="btn btn-oblong btn-light btn-sm tx-black font-weight-bold mg-r-5 pd-b-3"
                                                                        id="'deleteSubletButton' + iSublet"
                                                                        (click)="deleteSublet(sublet)">
                                                                        <i
                                                                            class="icon icon--trash_24_outline pd-r-10 mg-r-0"></i>

                                                                    </button>
                                                                </td>
                                                                <td *ngIf="disabledField && sublet.type == 'S'"
                                                                    class="text-center">
                                                                    <button
                                                                        class="btn btn-oblong btn-light btn-sm tx-black font-weight-bold mg-r-5"
                                                                        placement="top" ngbTooltip="Edit Sublet"
                                                                        data-toggle="modal" data-target="#updateClaim">
                                                                        <i class="fa fa-pencil"></i>
                                                                    </button>
                                                                </td>
                                                                <td *ngIf="sublet.type == 'O'" class="text-center">

                                                                </td>
                                                            </ng-container>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <!-- TOTALS VIEW -->
    <div class="row pd-t-30 pd-b-20">
        <div class="card col-12 pd-x-0">
            <div class="card-header d-flex justify-content-between pd-y-5">
                <div (click)="previewDetailsTotal()" class="card-option row align-items-center tx-24">
                    <a class="pd-r-10 mg-l-5"><i *ngIf="!showDetailsTotal" class="ion-ios-arrow-down"></i>
                        <i *ngIf="showDetailsTotal" class="ion-ios-arrow-up"></i></a>
                    <label class="section-title mg-b-0 tx-14 mg-t-0">TOTALS </label>
                </div>
            </div>

            <div *ngIf="showDetailsTotal" class="card-body">
                <div class="table-wrapper table-responsive">
                    <table id="datatable1" class="table table-expandable display responsive nowrap">
                        <thead>
                            <tr>
                                <th class="text-center bg-white zurich-blue-table">Job Code</th>
                                <th class="text-center bg-white zurich-blue-table">Parts</th>
                                <th class="text-center bg-white zurich-blue-table">Labor</th>
                                <th class="text-center bg-white zurich-blue-table">Sublet</th>
                                <th class="text-center bg-white zurich-blue-table">Other</th>
                                <th class="text-center bg-white zurich-blue-table">Sub Total</th>
                                <th class="text-center bg-white zurich-blue-table">Tax </th>
                                <th class="text-center bg-white zurich-blue-table">Deduct </th>
                                <th class="text-center bg-white zurich-blue-table">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let job of jobList; let i = index">
                                <ng-container *ngIf="!job.scsAdded">
                                    <td class="text-center">{{ job.jobNumber }}</td>
                                    <td class="text-center">{{ job.partRequestedTotal | currency: "$" }}</td>
                                    <td class="text-center">{{ job.laborRequestedTotal | currency: "$" }}</td>
                                    <td class="text-center">{{ job.subletRequestedTotal | currency: "$" }}</td>
                                    <td class="text-center">{{ job.otherRequestedTotal | currency: "$" }}</td>
                                    <td class="text-center">
                                        {{
                                        job.subTotalRequestedTotal | currency: "$"
                                        }}
                                    </td>
                                    <td class="text-center">{{ job.taxRequestedTotal | currency: "$" }}</td>
                                    <td class="text-center">{{ job.deductibleAmount | currency }} <span
                                            *ngIf="showDetailsTotal && showDeductibleWaiver && i == 0"
                                            class="tx-danger"> *</span></td>
                                    <td class="text-center tx-bold">
                                        {{
                                        job.totalRequested | currency: "$"
                                        }}
                                    </td>
                                </ng-container>
                            </tr>
                            <tr>
                                <td class="text-center tx-bold">Total</td>
                                <td class="text-center tx-bold">{{ claim.partRequestedTotal | currency: "$" }}</td>
                                <td class="text-center tx-bold">{{ claim.laborRequestedTotal | currency: "$" }}</td>
                                <td class="text-center tx-bold">{{ claim.subletRequestedTotal | currency: "$" }}</td>
                                <td class="text-center tx-bold">{{ claim.otherRequestedTotal | currency: "$" }}</td>

                                <td class="text-center tx-bold">
                                    {{
                                    claim.subTotalRequestedTotal | currency: "$"
                                    }}
                                </td>
                                <td class="text-center tx-bold">{{ claim.taxRequestedTotal | currency: "$" }}</td>

                                <td class="text-center tx-bold">{{jobList[0]?.deductibleAmount | currency}}</td>
                                <td class="text-center tx-bold">
                                    {{
                                    claim.totalRequested | currency: "$"
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="showDetailsTotal && showDeductibleWaiver" class="col-12 d-flex justify-content-end">
            <label class="form-control-label tx-black" style="font-size:13px;"><span class="tx-danger">*
                </span>Deductible waiver applied</label>
        </div>

    </div>
</div>

<div id="newLaborModal" class="modal">
    <div class="modal-dialog modal-lg modal-width" role="document">
        <form fxLayout="column" [formGroup]="laborForm" (ngSubmit)="submitLaborForm()">
            <div class="modal-content tx-size-sm">
                <div class="modal-header pd-x-20">
                    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                        Add Labor
                    </h6>
                    <button type="button" class="close" aria-label="Close" id="closeLaborModalButton"
                        data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="row">
                    <div class="col-md">
                        <div class="card">
                            <div class="card-body">
                                <div class="row ">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-control-label">Labor Description
                                            </label>
                                            <input #laborDescription class="form-control" type="text"
                                                name="laborDescription" value="" maxlength="20" placeholder=""
                                                [formControlName]="'laborDescription'" [ngClass]="{
                          'is-invalid': submittedLabor && f.laborDescription.errors
                        }" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row pd-t-20 mg-b-5">
                                    <div class="col-7 col-sm-4">
                                        <div class="form-group">
                                            <label class="form-control-label">Labor Quantity</label>
                                            <input class="form-control" type="number" name="laborHoursDiagnosis"
                                                value="" (blur)="getTotalLabor()" placeholder=""
                                                [formControlName]="'laborHours'" [ngClass]="{
                          'is-invalid': submittedLabor && f.laborHours.errors
                        }" />
                                        </div>


                                    </div>
                                    <div class="align-self-center tx-black" style="font-size: 22px; margin-top: 17px;">X
                                    </div>
                                    <div class="col-7 col-sm-4">
                                        <div class="form-group">
                                            <label class="form-control-label">Labor Rate
                                                <i class="fa fa-info-circle zurich-blue" placement="auto"
                                                    container="body" triggers="click"
                                                    ngbTooltip="Labor Rates in this field are populated based on the number entered on the Claims Information screen. 
                          You can overwrite that amount for flat fee or menu priced items (i.e. mount and balance, tire repair, etc.)"></i>
                                            </label>
                                            <input (blur)="transformAmount($event, 'labor')"
                                                [(ngModel)]="formattedLaborRate" class="form-control"
                                                [formControlName]="'laborRate'" [ngClass]="{
                          'is-invalid': (submittedLabor && f.laborRate.errors) || laborRateError
                        }" />
                                        </div>
                                    </div>
                                    <div class="align-self-center tx-black" style="font-size: 22px; margin-top: 17px;">=
                                    </div>
                                    <div class="col-7 col-sm">
                                        <div class="form-group">
                                            <label class="form-control-label">Total</label>
                                            <input rows="2" class="form-control" placeholder=""
                                                [formControlName]="'total'" disabled [ngClass]="{
                          'is-invalid': submittedLabor && f.total.errors
                        }" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-oblong btn-primary btn">
                        Add
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<div id="newPartModal" class="modal">
    <div class="modal-dialog modal-lg modal-width" role="document">
        <form fxLayout="column" [formGroup]="partForm" (ngSubmit)="submitPartForm()">
            <div class="modal-content tx-size-sm">
                <div class="modal-header pd-x-20">
                    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">Add Part</h6>
                    <button type="button" class="close" aria-label="Close" id="closePartModalButton"
                        data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-control-label">Part Number </label>
                                    <input tabindex="1" #partNumber id="partNumber" class="form-control" type="text"
                                        name="partNumber" value="" placeholder="Enter Part Number"
                                        [formControlName]="'partNumber'" (blur)="blurPartDescription()" [ngClass]="{
                      'is-invalid': submittedPart && fPart.partNumber.errors
                    }" />
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label">Part Description </label>
                                    <input class="form-control" type="text" name="partDescription" value=""
                                        placeholder="" [formControlName]="'partDescription'" disabled />
                                </div>
                            </div>

                            <div class="col-6">

                                <div *ngIf="showAfterMarketSelectField" class="form-group mg-b-10-force">
                                    <label class="form-control-label">Is this an after-market part?
                                        <span class="tx-danger">*</span></label>
                                    <select tabindex="4" class="form-control select2"
                                        [formControlName]="'isAfterMarket'" data-placeholder="Select"
                                        (change)="showDescripitonField()" [ngClass]="{
                      'is-invalid': submittedPart && fPart.isAfterMarket.errors
                    }">
                                        <option label="Select" [selected]="true" [hidden]="true">
                                            Select
                                        </option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div class="form-group" *ngIf="showDescField">
                                    <label class="form-control-label">Please provide a description of the part
                                    </label>
                                    <textarea tabindex="5" rows="2" class="form-control"
                                        name="afterMarketPartDescription" value=""
                                        [formControlName]="'afterMarketPartDescription'" [ngClass]="{
                      'custom-error':
                        (submittedPart &&
                        fPart.afterMarketPartDescription?.errors) ||
                        fPart.afterMarketPartDescription?.errors?.maxlength
                    }"></textarea>
                                    <div class="row justify-content-end pd-r-15">
                                        <span>{{fPart.afterMarketPartDescription?.value?.length}}/60</span>
                                    </div>
                                    <label class="tx-danger"
                                        *ngIf="fPart.afterMarketPartDescription?.errors?.maxlength">Part description is
                                        longer than 60 characters
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row pd-t-20 mg-b-5">
                            <div class="col-7 col-sm-4">
                                <div class="form-group">
                                    <label class="form-control-label">Quantity </label>
                                    <input tabindex="2" class="form-control" type="number" name="quantity" value=""
                                        (blur)="getTotalPart()" placeholder="Enter Quantity"
                                        [formControlName]="'quantity'" [ngClass]="{
                      'is-invalid': submittedPart && fPart.quantity.errors
                    }" />
                                </div>


                            </div>
                            <div class="align-self-center tx-black" style="font-size: 22px; margin-top: 17px;">X</div>
                            <div class="col-7 col-sm-4">
                                <div class="form-group">
                                    <label class="form-control-label">Unit Price </label>
                                    <input tabindex="3" class="form-control" name="formattedUnitPricePart" value="$ .00"
                                        (blur)="transformAmount($event, 'part')" [(ngModel)]="formattedUnitPricePart"
                                        placeholder="$ .00" [formControlName]="'unitPrice'" [ngClass]="{
                      'is-invalid': (submittedPart && fPart.unitPrice.errors) || unitPricePartError
                    }" />
                                </div>
                            </div>
                            <div class="align-self-center tx-black" style="font-size: 22px; margin-top: 17px;">=</div>
                            <div class="col-7 col-sm">
                                <div class="form-group">
                                    <label class="form-control-label">Total <span *ngIf="partMarkup != 0"
                                            class="tx-danger">*</span></label>
                                    <input rows="2" class="form-control" placeholder="" [formControlName]="'total'"
                                        disabled [ngClass]="{
                      'is-invalid': submittedPart && fPart.total.errors
                    }" />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="partMarkup != 0" class="row tx-primary"><label><span class="tx-danger">* </span>The
                                parts pricing adjustment (noted on the Claim Information screen) will be applied during
                                the adjustment of the claim if applicable.</label></div>
                    </div>

                    <div class="modal-footer">

                        <button type="submit" class="btn btn-oblong btn-primary btn" tabindex="8">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div id="newSubletModal" class="modal">
    <div class="modal-dialog modal-lg modal-width" role="document">
        <form fxLayout="column" [formGroup]="subletForm" (ngSubmit)="submitSubletForm()">
            <div class="modal-content tx-size-sm">
                <div class="modal-header pd-x-20">
                    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                        Add Sublet
                    </h6>
                    <button type="button" class="close" aria-label="Close" id="closeSubletModalButton"
                        data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="row">
                    <div class="col-md">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-control-label">Sublet Description
                                            </label>
                                            <input #subletDescription class="form-control" type="text"
                                                name="subletDescription" value="" maxlength="100"
                                                placeholder="Type description here"
                                                [formControlName]="'subletDescription'" [ngClass]="{
                          'is-invalid': submittedSublet && fSublet.subletDescription.errors
                        }" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group">
                                            <label class="form-control-label">Unit Price</label>
                                            <input class="form-control" name="subletUnitPrice" value="$ .00"
                                                (blur)="transformAmount($event, 'sublet')"
                                                [(ngModel)]="formattedUnitPriceSublet" placeholder="$ .00"
                                                [formControlName]="'subletUnitPrice'" [ngClass]="{
                          'is-invalid': (submittedSublet && fSublet.subletUnitPrice.errors) || unitPriceSubletError
                        }" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label class="form-control-label">Sublets are only to be used on third party
                                            invoices</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-oblong btn-primary btn">
                        Add
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<div id="updateClaim" class="modal">
    <div class="modal-dialog modal-lg modal-width-bug" role="document">

        <div class="modal-content tx-size-sm">
            <div class="modal-header pd-x-20 pd-b-30">
                <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                    Need to request changes?
                </h6>
                <button type="button" class="close" aria-label="Close" id="updateClaimModalButton" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="card">
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-10 text-center">
                                    <p>Changes to submitted claims can be requested by entering a Note.
                                    </p>
                                    <p>Click the Note icon below to add a note now or select the X to close this box.
                                    </p>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <i (click)="openNotesSection()" class="fa fa-3x far tx-primary fa-edit"
                                    style="cursor: pointer;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>