import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Contract } from '../_model/contract';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private apiUrl: string;
  private headers = new HttpHeaders();
  contracts = new BehaviorSubject<Contract[]>([]);
  contract = new BehaviorSubject<Contract>({});
  contractId = new BehaviorSubject<string>('');
  contractIndex = new BehaviorSubject<number>(-1);

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  searchContract(contractInfoDto) {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http.post<any[]>(
      this.apiUrl + '/contract-info',
      contractInfoDto,
      httpOptions
    );
  }

  getClaimByContractNumber(contractNumber) {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http.get<any[]>(
      this.apiUrl + '/contract/' + contractNumber,
      httpOptions
    );
  }

  findContractByNumber(contractNumber) {
    const httpOptions = {
      headers: this.headers,
    };

    return this.http.get<any[]>(
      this.apiUrl + '/contract/' + contractNumber,
      httpOptions
    );
  }
}
