import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Claim } from '../_model/claim';
import { Job } from '../_model/job';
import { environment } from 'src/environments/environment';
import { OktaAuthService } from '@okta/okta-angular';
import { ReleaseNotes } from '../_model/releaseNotes';

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  private apiUrl: string;
  private subletUrl: string;
  private headers = new HttpHeaders();
  private httpOptions;
  claim = new BehaviorSubject<Claim>({});
  job = new BehaviorSubject<Job>({});
  currentUrl = new BehaviorSubject<string>('');
  sellerDataReceived = new BehaviorSubject<boolean>(false);
  releaseNotes = new BehaviorSubject<ReleaseNotes[]>([]);
  releaseNotesLoaded = new BehaviorSubject<boolean>(false);

  accountName;
  userName;
  accountNumber;
  email;
  currentStep = -1;
  startClaimFromSearch = false;
  editClaim: boolean = false;
  previewActiveClaim: boolean = false;
  userId: string;
  userInfoData: string;

  exportButtonVisible: boolean = false;

  //SELLER API
  billingEditable: boolean = true;
  billingMethod: string = "";
  laborBillingRate: number = 0;
  laborTax: number = 0;
  partTax: number = 0;
  partMarkup: number = 0;
  payeeNameLabel: string = "";
  paymentMethod: string = "";
  faxesSeller: any[] = [];
  emailsSeller: any[] = [];
  automationEnabled: boolean = false;
  selfAuthMax: number = 0;

  constructor(private http: HttpClient, public oktaAuth: OktaAuthService) {
    this.apiUrl = environment.apiUrl;
    let envName = environment.environmentName;
    if (envName == "znadev") {
      this.subletUrl = environment.subletUrl;
    }
    this.exportButtonVisible = environment.exportButtonVisible;

    this.httpOptions = {
      headers: this.headers,
    };
    this.ngOnInit();
  }
  async ngOnInit() {
    let isOktaEnabled = environment.isOktaEnabled;

    if (!isOktaEnabled) {
      if (localStorage.getItem('myAccountData')) {
        let userInfo = JSON.parse(localStorage.getItem('myAccountData'));
        this.userName = userInfo.user_name;
        this.accountName = userInfo.account_name;
        this.accountNumber = userInfo.account_number;
        this.email = userInfo.user_email;
        this.userName = userInfo.user_name;
        this.userId = userInfo.user_id;
      }

    }
    else {
      if (localStorage.getItem('myAccountData')) {
        let userInfo = JSON.parse(localStorage.getItem('myAccountData'));
        this.accountName = userInfo.account_name;
        this.accountNumber = userInfo.account_number;
        this.email = userInfo.user_email;
        this.userId = userInfo.user_id;

        this.userName = userInfo.user_name;
      }
    }
  }

  getAllActive(userAccountParam, size, sortQuery?: string) {
    let url: string = '/claims?v=ACTIVE' + userAccountParam + size;
    if (sortQuery)
      url += sortQuery;

    return this.http.get<any[]>(
      this.apiUrl + url,
      this.httpOptions
    );
  }
  getAllDraft(userAccountParam, size) {
    return this.http.get<any[]>(
      this.apiUrl + '/claims?v=DRAFT' + userAccountParam + size,
      this.httpOptions
    );
  }
  getAllAction(userAccountParam, size) {
    return this.http.get<any[]>(
      this.apiUrl + '/claims?v=ACTION' + userAccountParam + size,
      this.httpOptions
    );
  }
  getClaimById(claimId: string) {
    return this.http.get<any>(
      this.apiUrl + '/claims/' + claimId,
      this.httpOptions
    );
  }
  createClaim(claimObj) {
    return this.http.post<Claim>(
      this.apiUrl + '/claims',
      claimObj,
      this.httpOptions
    );
  }

  unlockClaim(claimId) {
    return this.http.put(this.apiUrl + '/claims/unlock/' + claimId, {}, this.httpOptions);
  }

  updateClaim(claimObj) {
    return this.http.put(this.apiUrl + '/claims', claimObj, this.httpOptions);
  }
  updateClaimStatus(claimId, statusId) {
    return this.http.put(this.apiUrl + '/claims/' + claimId + "/" + statusId + "?n=" + this.userName, {}, this.httpOptions);
  }
  deleteClaim(claimId) {
    return this.http.delete<Claim>(
      this.apiUrl + '/claims/' + claimId,
      this.httpOptions
    );
  }

  getJobs(claimId) {
    return this.http.get<any[]>(
      this.apiUrl + '/claims/' + claimId + '/jobs',
      this.httpOptions
    );
  }

  addJob(claimId, jobData) {
    return this.http.post(
      this.apiUrl + '/claims/jobs/' + claimId,
      jobData,
      this.httpOptions
    );
  }

  updateJob(jobData) {
    return this.http.put(
      this.apiUrl + '/claims/jobs',
      jobData,
      this.httpOptions
    );
  }

  deleteJob(jobId) {
    return this.http.delete(
      this.apiUrl + '/claims/jobs/' + jobId,
      this.httpOptions
    );
  }

  addLabor(claimId, jobNumber, laborData) {
    return this.http.post(
      this.apiUrl + '/claims/labors/' + claimId + '/' + jobNumber,
      laborData,
      this.httpOptions
    );
  }

  updateLabor(laborData) {
    return this.http.put(
      this.apiUrl + '/claims/jobs/labors',
      laborData,
      this.httpOptions
    );
  }

  addSublet(subletData) {
    return this.http.post(
      this.apiUrl + '/claims/jobs/sublets/',
      subletData,
      this.httpOptions
    );
  }

  updateSublet(subletData) {
    return this.http.put(
      this.apiUrl + '/claims/jobs/sublets/',
      subletData,
      this.httpOptions
    );
  }

  deleteLabor(laborId) {
    return this.http.delete(
      this.apiUrl + '/claims/jobs/labors/' + laborId,
      this.httpOptions
    );
  }

  deleteSublet(subletId) {
    let url = this.subletUrl ? this.subletUrl : this.apiUrl;
    return this.http.delete(
      url + '/claims/jobs/sublets/' + subletId,
      this.httpOptions
    );
  }

  addPart(claimId, jobNumber, partData) {
    return this.http.post(
      this.apiUrl + '/claims/parts/' + claimId + '/' + jobNumber,
      partData,
      this.httpOptions
    );
  }

  updatePart(partData) {
    return this.http.put(
      this.apiUrl + '/claims/jobs/parts',
      partData,
      this.httpOptions
    );
  }

  deletePart(partId) {
    return this.http.delete(
      this.apiUrl + '/claims/jobs/parts/' + partId,
      this.httpOptions
    );
  }

  findPartDescription(partNumber) {
    return this.http.get(
      this.apiUrl + '/claims/jobs/parts/' + partNumber + '/desc',
      this.httpOptions
    );
  }

  findPartDescriptionPost(partData) {
    return this.http.post(
      this.apiUrl + '/claims/jobs/parts/desc',
      partData,
      this.httpOptions
    );
  }

  sendClaim(claimData, claimId) {
    return this.http.post(
      this.apiUrl + '/claims/' + claimId + '/send',
      claimData,
      this.httpOptions
    );
  }

  addNote(claimId, noteData) {
    return this.http.post(
      this.apiUrl + '/claims/notes/' + claimId + "?dt=" + encodeURIComponent(this.accountName),
      noteData,
      this.httpOptions
    );
  }

  getNotes(claimId) {
    return this.http.get<any[]>(
      this.apiUrl + '/claims/' + claimId + '/notes',
      this.httpOptions
    );
  }

  deleteNote(_claimId, noteNumber) {
    return this.http.delete<any[]>(
      this.apiUrl + '/claims/notes/' + noteNumber,
      this.httpOptions
    );
  }

  addAttachment(claimId, formData) {
    return this.http.post<any>(
      this.apiUrl + '/documents/' + claimId + "?n=" + this.userName + "&dt=" + encodeURIComponent(this.accountName),
      formData,
      this.httpOptions
    );
  }

  addFeedback(formData, feedback: string) {
    return this.http.post<any>(
      this.apiUrl + '/feedback' + "?n=" + this.userName + "&dt=" + encodeURIComponent(this.accountName) + "&note=" + feedback,
      formData,
      this.httpOptions
    );
  }

  addAttachmentWithAdditionalParams(claimId: string, formData, note: string, type: string) {
    let apiUrlTmp = this.apiUrl;
    apiUrlTmp += '/documents';

    if (claimId && claimId.length > 0)
      apiUrlTmp += "/" + claimId;

    apiUrlTmp += "?n=" + this.userName;
    apiUrlTmp += "&dt=" + encodeURIComponent(this.accountName);
    if (note && note.length > 0)
      apiUrlTmp += "&note=" + encodeURIComponent(note);

    if (type && type.length > 0)
      apiUrlTmp += "&type=" + type;

    return this.http.post<any>(
      apiUrlTmp,
      formData,
      this.httpOptions
    );
  }

  getAttachments(claimId, type?: string) {
    let param = claimId;
    if (type && type != null) {
      param += "?type=" + type;
    }
    return this.http.get<any[]>(
      this.apiUrl + '/documents/' + param,
      this.httpOptions
    );
  }

  downloadAttachment(attachment) {


    return this.http.get<any[]>(
      this.apiUrl + '/documents/content/' + attachment.fileId,
      this.httpOptions
    );
  }

  deleteAttachment(fileId) {
    return this.http.delete<any[]>(
      this.apiUrl + '/documents/' + fileId,
      this.httpOptions
    );
  }

  getJobComponents(jobType: string, planId: number) {
    return this.http.get<any[]>(
      this.apiUrl + '/codes/components?' + "jobType=" + jobType + "&planId=" + planId,
      this.httpOptions
    );
  }

  getCodeListForComponent(componentId, planId: number) {
    return this.http.get<any[]>(
      this.apiUrl + '/codes/parts/' + componentId + "?planId=" + planId,
      this.httpOptions
    );

  }

  getStatusList() {
    return this.http.get<any[]>(
      this.apiUrl + '/claims/statuses/',
      this.httpOptions
    );
  }

  getFilteredClaims(parameters) {
    return this.http.get<any[]>(
      this.apiUrl + '/claims/list' + parameters,
      this.httpOptions
    );
  }

  getClaimsCount(parameters) {
    return this.http.get<any[]>(
      this.apiUrl + '/claims/count' + parameters,
      this.httpOptions
    );
  }

  getEventLog(timePeriod: number) {
    return this.http.get<any[]>(
      this.apiUrl + '/events/' + this.accountNumber + "/" + timePeriod,
      this.httpOptions
    );
  }

  getSellerInfoById(id: string) {
    return this.http.get<any[]>(
      this.apiUrl + '/seller/' + encodeURIComponent(id),
      this.httpOptions
    );
  }

  getTireAndWheelConfig(name: string) {
    return this.http.get<any[]>(
      this.apiUrl + '/config/' + name,
      this.httpOptions
    );
  }

  checkRepairOrderNumber(roNum: string, contractNum, claimId: string) {
    let claimIdPart = claimId ? "&claimId=" + claimId : "";

    return this.http.get<any[]>(
      this.apiUrl + '/claims/check/ro/' + roNum + "?contractNumber=" + contractNum + claimIdPart,
      this.httpOptions
    );
  }

  getLaborRateByLossDate(date: string, contractInfo, sellerId: string) {
    return this.http.post<any[]>(
      this.apiUrl + '/seller/' + encodeURIComponent(sellerId) + '/rates/' + date,
      contractInfo,
      this.httpOptions
    );
  }

  getUserConfig() {
    return this.http.get<any[]>(
      this.apiUrl + '/config/user',
      this.httpOptions
    );
  }

  runReport(claimId: string) {
    return this.http.get<any>(
      this.apiUrl + "/stp/run/claim?claimId=" + claimId,
      this.httpOptions
    )
  }

  downloadReport(reportId: string) {
    return this.http.get<any[]>(
      this.apiUrl + "/stp/report/" + reportId,
      this.httpOptions
    )
  }

  getReleaseNotes() {
    return this.http.get<any[]>(
      this.apiUrl + '/release-notes',
      this.httpOptions
    );
  }
}