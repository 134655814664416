import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ReleaseNotes } from 'src/app/_model/releaseNotes';
import { ClaimService } from 'src/app/_services/claim.service';

@Component({
  selector: 'app-release-notes-main',
  templateUrl: './release-notes-main.component.html',
  styleUrls: ['./release-notes-main.component.css']
})
export class ReleaseNotesMainComponent implements OnInit, OnDestroy {

  constructor(private claimService: ClaimService) { }

  changingValue: Subject<boolean> = new Subject();
  currentMonth: BehaviorSubject<any> = new BehaviorSubject([]);

  monthList: ReleaseNotes[] = [];
  selectedRelaseNote: any;
  indexNum: number = 0;
  hasNextMonth: boolean = true;
  hasPreviousMonth: boolean = false;

  releaseNotesSubscription: Subscription;
  releaseNotesLoadedSubscription: Subscription;
  releaseNotesLoaded: boolean = false;

  ngOnInit(): void {
    this.releaseNotesSubscription = this.claimService.releaseNotes.subscribe(value => {
      if (value?.length > 0) {
        this.monthList = value;
        this.currentMonth.next(this.monthList[0].notes);
        this.selectMonth(0);
      }
    });

    this.releaseNotesLoadedSubscription = this.claimService.releaseNotesLoaded.subscribe(value => {
      this.releaseNotesLoaded = value;
    });
  };

  ngOnDestroy(): void {
    this.releaseNotesSubscription?.unsubscribe();
    this.releaseNotesLoadedSubscription?.unsubscribe();
  }

  updateCounter() {
    this.changingValue.next(true);
    this.currentMonth.next(this.monthList[0]?.notes);
    this.selectedRelaseNote = this.monthList[0];
  }

  selectMonth(indexNum: number) {
    let tmpIndexNum = this.indexNum + indexNum;

    if (this.monthList?.length > 0) {
      this.selectedRelaseNote = this.monthList[tmpIndexNum];
      this.currentMonth.next(this.monthList[tmpIndexNum]?.notes);
    }
  }

  increaseCounter() {
    if (this.indexNum >= this.monthList?.length) {
      this.hasNextMonth = false;
      return;
    }


    if (this.indexNum + 3 < this.monthList?.length) {
      this.indexNum += 3;
      if ((this.monthList?.length - this.indexNum) < 3) {
        this.hasNextMonth = false;
      } else {
        this.hasNextMonth = true;
      }
      this.hasPreviousMonth = true;
      return;
    } else if (this.indexNum + 2 < this.monthList?.length) {
      if (this.monthList?.length % 3 == 2) {
        this.indexNum += 2;
        this.hasNextMonth = false;
        this.hasPreviousMonth = true;
        return;
      }
    } else if (this.indexNum + 1 < this.monthList?.length) {
      if (this.monthList?.length % 3 == 1) {
        this.indexNum += 1;
        this.hasNextMonth = false;
        this.hasPreviousMonth = true;
        return;
      }
    }
    this.hasNextMonth = false;
    if (this.indexNum > 3) {
      this.hasPreviousMonth = true;
    } else {
      this.hasPreviousMonth = false;
    }

  }
  decreaseCounter() {
    if ((this.monthList?.length - this.indexNum) > 0 && this.monthList?.length > 3) {
      this.hasNextMonth = true;
    }
    if (this.indexNum >= 3)
      this.indexNum -= 3;

    if (this.indexNum < 3) {
      this.indexNum = 0;
      this.hasPreviousMonth = false;
    }
  }
}
