import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ReleaseNotes } from 'src/app/_model/releaseNotes';

@Component({
  selector: 'app-new-item-list',
  templateUrl: './new-item-list.component.html',
  styleUrls: ['./new-item-list.component.css']
})
export class NewItemListComponent implements OnInit {
  pageNum: string = "1/5";
  detailsTitle = "Part Description character count";
  imgPath = "./assets/slim/img/claimsAutoRefresh.png";
  pageNumber: number = 0;
  numberOfPages: number = 0;
  releaseNoteList: any[] = [];
  constructor() { }

  @Input() resetCounter: Subject<boolean>;
  @Input() monthValue: Subject<any[]>;

  ngOnInit() {
    this.resetCounter.subscribe(v => {
      this.pageNumber = 0;
    });
    this.monthValue.subscribe((v: any[]) => {
      this.releaseNoteList = v;
      this.pageNumber = 0;
    });
    this.numberOfPages = this.releaseNoteList?.length;
  }

  increaseCounter() {
    if (this.pageNumber >= this.releaseNoteList?.length - 1) {
      return;
    }
    this.pageNumber++;
  }

  decreaseCounter() {
    if (this.pageNumber <= 0) {
      return;
    }
    this.pageNumber--;
  }
}
