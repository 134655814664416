import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { interval, merge, Observable, OperatorFunction, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Claim } from 'src/app/_model/claim';
import { Job } from 'src/app/_model/job';
import { ClaimService } from 'src/app/_services/claim.service';

@Component({
  selector: 'app-claim-details',
  templateUrl: './claim-details.component.html',
  styleUrls: ['./claim-details.component.css'],
})
export class ClaimDetailsComponent implements OnInit, OnDestroy {
  @Output() messageEvent = new EventEmitter<boolean>();
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  editJobBoolean: boolean = false;
  showJobSummary: boolean = false;
  createAnother: boolean = false;
  createAnotherRental: boolean = false;
  claimDetailsForm: FormGroup;
  rentalRoadsideForm: FormGroup;
  claimSubscription: Subscription;
  claim: Claim;
  isEngineSelected: boolean = false;
  isTransmissionSelected: boolean = false;
  isWheelAndTireSelected: boolean = false;
  isWheelAndTireJobSelected: boolean = false;

  formattedTireTaxPrice = '';
  tireTaxPriceError: boolean = false;

  submitted: boolean = false;
  submittedRental: boolean = false;
  noJobsAdded: boolean = false;
  stepUpdated: boolean = false;
  codes: any[] = [];
  codesBenefit: any[] = [];
  jobComponents: JSON[] = [];
  jobComponentsBenefit: JSON[] = [];
  tireAndWheelComponents: any[] = [];
  jobList: Job[] = [];
  pressedContinue: boolean = false;
  disabledField: boolean = false;

  OTHER: string = "OTHER";
  BENEFIT: string = "BENEFIT";
  showRental: boolean = false;
  showRentalDesc: boolean = false;
  showRoadSide: boolean = false;
  showRoadSideDesc: boolean = false;
  rentalRatePerDayError: boolean = false;
  roadSideAmountError: boolean = false;
  formattedRentalRatePerDay: string = '';
  formattedAmount: string = '';
  indexNum = -1;
  header: string = "Delete Claim";
  message: string = "";
  buttonMessage: string = "Delete"
  disableDelete: boolean = false;
  editJobId: string;
  editJobLossCodeObject;
  createJobPressed: boolean = false;
  createRentalPressed: boolean = false;
  showSpinnerDraft: boolean = false;
  hideRentalQuestion: boolean = false;
  disableJobComponentField: boolean = false;
  disableJobComponentBenefitField: boolean = false;

  searchCurrency = '$';
  replaceWith = '';
  searchComma = ',';

  showMaxAmountError: boolean = false;
  maxAmountError: string = "Amount exceeds contract limit of $";

  componentsOtherSubscription: Subscription;
  componentsBenefitSubscription: Subscription;

  constructor(
    private readonly fb: FormBuilder,
    private claimService: ClaimService,
    private toastr: ToastrService,
    private currencyPipe: CurrencyPipe,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.claimSubscription?.unsubscribe();
    this.componentsOtherSubscription?.unsubscribe();
    this.componentsBenefitSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.toastr.clear();

    this.claimDetailsForm = this.fb.group({
      jobComponent: [, Validators.required],
      jobComponentIndividual: [],
      causeSelect: [,],
      tireTaxPrice: [],
      customerComplaintDesc: [, Validators.compose([Validators.required, Validators.maxLength(900)])],
      causeDesc: [, Validators.compose([Validators.required, Validators.maxLength(900)])],
      correctionDesc: [, Validators.compose([Validators.required, Validators.maxLength(900)])],
      transmissionFluidLevelAndCondition: [,],
      transmissionFluidLeaks: [,],
      transmissionFaultCodesPresent: [,],
      transmissionFaultCodes: [,],
      transmissionFactoryOfferInternalParts: [,],
      transmissionTearDownConfirmFailure: [,],
      model: [, Validators.required],
      engineFluidAndCondition: [,],
      engineFluidLeaks: [,],
      engineFaultCodePresent: [,],
      engineFaultCodes: [,],
      engineTearDownConfirmFailure: [,],
      driverFront: [,],
      driverFrontTreadDepth: [,],
      passengerFront: [,],
      passengerFrontTreadDepth: [,],
      driverRear: [,],
      driverRearTreadDepth: [,],
      passengerRear: [,],
      passengerRearTreadDepth: [,],
    });

    this.rentalRoadsideForm = this.fb.group({
      jobComponentRental: [, Validators.required],
      jobComponentRentalIndividual: [],
      jobLossCodeDescRental: [, Validators.required],
      rentalRatePerDay: [, Validators.required],
      numberOfRentalDays: [, Validators.compose([Validators.required, Validators.min(0.1), Validators.max(99)])],
      rentalDescChoice: [, Validators.required],
      rentalDesc: [, Validators.required],
      total: [],
      roadsideAmount: [, Validators.required],
      roadsideDescChoice: [, Validators.required],
      roadsideDesc: [, Validators.required]
    });

    if (this.claimService.previewActiveClaim) {
      this.claimDetailsForm.disable();
    } else {
      this.populateCauseForTireAndWheel();
    }
    this.disabledField = this.claimService.previewActiveClaim;

    this.claimDetailsForm
      .get('jobComponent')
      .valueChanges.subscribe((value) => {
        if (value && value.name && value.name.includes('Transmission')) {
          this.isTransmissionSelected = true;
          this.isEngineSelected = false;
          this.isWheelAndTireSelected = false;
          this.tireTaxPriceError = false;
          this.clearEngineValidators();
        } else if (value && value.name && value.name.includes('Engine')) {
          this.isTransmissionSelected = false;
          this.isEngineSelected = true;
          this.isWheelAndTireSelected = false;
          this.tireTaxPriceError = false;

          this.isWheelAndTireJobSelected = false;
          this.clearTransmisionValidators();
        } else if (value && value.name && value.name.includes('Tire') && value.name.includes('Wheel')) {
          this.isTransmissionSelected = false;
          this.isEngineSelected = false;
          this.isWheelAndTireJobSelected = true;
          this.clearValidators();
        } else {
          this.isTransmissionSelected = false;
          this.isEngineSelected = false;
          this.isWheelAndTireSelected = false;
          this.tireTaxPriceError = false;

          this.isWheelAndTireJobSelected = false;
          this.clearValidators();
        }
        this.claimDetailsForm.updateValueAndValidity();
        this.revalidateForm();
        this.getCodeListForComponent(value, this.OTHER);
      });

    this.claimDetailsForm
      .get('transmissionFaultCodesPresent')
      .valueChanges.subscribe((value) => {
        if (value && value === 'true') {
          this.claimDetailsForm.controls[
            'transmissionFaultCodes'
          ].setValidators(Validators.required);
        } else {
          this.claimDetailsForm.get('transmissionFaultCodes').clearValidators();
        }
        this.claimDetailsForm.controls[
          'transmissionFaultCodes'
        ].updateValueAndValidity();
      });

    this.claimDetailsForm
      .get('engineFaultCodePresent')
      .valueChanges.subscribe((value) => {

        if (value && value === 'true') {
          this.claimDetailsForm
            .get('engineFaultCodes')
            .setValidators(Validators.required);
        } else {
          this.claimDetailsForm.get('engineFaultCodes').clearValidators();
        }
        this.claimDetailsForm.controls[
          'engineFaultCodes'
        ].updateValueAndValidity();
      });

    this.claimSubscription = this.claimService.claim.subscribe((value) => {
      this.claim = value;
      if (this.claim.jobs || this.claimService.editClaim) this.openJobSummary();
      this.hideRentalQuestion = false;
      for (let i = 0; i < this.claim.jobs?.length; i++) {
        if (this.claim.jobs[i].type != this.BENEFIT)
          this.hideRentalQuestion = true;
      }
    });
    this.claimDetailsForm
      .get('model')
      .valueChanges.subscribe((value) => {
        if (
          value &&
          value.partDesc && (
            (
              typeof this.claimDetailsForm.controls.jobComponent?.value === 'string' &&
              this.claimDetailsForm.controls.jobComponent?.value?.includes('Wheel') &&
              this.claimDetailsForm.controls.jobComponent?.value?.includes('Tire')
            ) ||
            (
              this.jobComponents?.length == 1 && this.claimDetailsForm.controls.jobComponentIndividual.value &&
              this.claimDetailsForm.controls.jobComponentIndividual.value?.includes('Wheel') &&
              this.claimDetailsForm.controls.jobComponentIndividual.value?.includes('Tire')
            )
          ) &&
          value?.partDesc?.toLowerCase().startsWith("Tire".toLowerCase())
        ) {
          this.isWheelAndTireSelected = true;
          this.isTransmissionSelected = false;
          this.isEngineSelected = false;
          this.isWheelAndTireJobSelected = true;

          this.clearValidators();
        } else {
          this.isWheelAndTireSelected = false;
          this.tireTaxPriceError = false;

        }
      });
    this.claimDetailsForm
      .get('jobComponentIndividual')
      .valueChanges.subscribe((value) => {
        if (value && value.name && value.name.includes('Transmission')) {
          this.isTransmissionSelected = true;
          this.isEngineSelected = false;
          this.isWheelAndTireSelected = false;
          this.isWheelAndTireJobSelected = false;
          this.clearEngineValidators();
        } else if (value && value.name && value.name.includes('Engine')) {
          this.isTransmissionSelected = false;
          this.isEngineSelected = true;
          this.isWheelAndTireSelected = false;
          this.tireTaxPriceError = false;
          this.isWheelAndTireJobSelected = false;
          this.clearTransmisionValidators();
        } else if ((value && (typeof value === 'string') && value.includes('Wheel') && value.includes('Tire')) || (value && value?.name && value?.name?.includes('Wheel') && value?.name?.includes('Tire'))) {
          this.isTransmissionSelected = false;
          this.isEngineSelected = false;
          this.isWheelAndTireJobSelected = true;
          this.clearValidators();
        }
        else {
          this.isTransmissionSelected = false;
          this.isEngineSelected = false;
          this.isWheelAndTireSelected = false;
          this.tireTaxPriceError = false;
          this.isWheelAndTireJobSelected = false;
          this.clearTransmisionValidators();
          this.clearEngineValidators();
        }
        this.claimDetailsForm.updateValueAndValidity();
        this.revalidateForm();
        this.getCodeListForComponent(value, this.OTHER);
      });

    //get components every 4 second
    const source = interval(4000);
    this.componentsOtherSubscription = source.subscribe({ next: () => this.getJobComponents(this.OTHER, this.claim.planId) });


    //get components every 4 second
    const sourceBenefit = interval(4000);
    this.componentsBenefitSubscription = sourceBenefit.subscribe({ next: () => this.getJobComponents(this.BENEFIT, this.claim.planId) });

    /*RENTAL ROADSIDE JOB */

    this.rentalRoadsideForm
      .get('jobComponentRental')
      .valueChanges.subscribe((value) => {
        this.rentalRoadsideForm.updateValueAndValidity();
        this.getCodeListForComponent(value, this.BENEFIT);
      });
    this.rentalRoadsideForm
      .get('jobComponentRentalIndividual')
      .valueChanges.subscribe((value) => {
        this.rentalRoadsideForm.updateValueAndValidity();
        this.getCodeListForComponent(value, this.BENEFIT);
      });
    this.rentalRoadsideForm
      .get('jobLossCodeDescRental')
      .valueChanges.subscribe((value) => {
        if (
          value &&
          value.partDesc &&
          this.rentalRoadsideForm.controls.jobLossCodeDescRental.value?.partDesc?.toLowerCase().startsWith('Rental'.toLocaleLowerCase())
        ) {
          this.showRental = true;
          this.showRoadSide = false;
          this.showRoadSideDesc = false;
        } else if (
          value &&
          value.partDesc &&
          this.rentalRoadsideForm.controls.jobLossCodeDescRental.value?.partDesc?.toLowerCase().includes('Roadside'.toLocaleLowerCase())
        ) {
          this.showRental = false;
          this.showRoadSide = true;
          this.showRentalDesc = false;
        } else {
          if (!this.editJobBoolean) {
            this.showRental = false;
            this.showRoadSide = false;
            this.showRentalDesc = false;
            this.showRoadSideDesc = false;
          }
        }
      });

    this.rentalRoadsideForm
      .get('rentalDescChoice')
      .valueChanges.subscribe((value) => {
        if (value && value === 'true') {
          this.showRentalDesc = true;
        } else {
          this.showRentalDesc = false;
        }
      });

    this.rentalRoadsideForm
      .get('roadsideDescChoice')
      .valueChanges.subscribe((value) => {
        if (value && value === 'true') {
          this.showRoadSideDesc = true;
        } else {
          this.showRoadSideDesc = false;
        }
      });
  }
  populateCauseForTireAndWheel() {
    this.claimService.getTireAndWheelConfig('TWCause').subscribe((res: any) => {

      if (res)
        this.tireAndWheelComponents = res?.values;
    },
      (error) => {
        console.log(error);
        this.toastr.error(
          'Something went wrong while fetching Tire & Wheel causes, please try again. Error code: ' + error.status,
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      });
  }

  clearValidators() {
    this.claimDetailsForm.get('engineFluidAndCondition').clearValidators();
    this.claimDetailsForm.get('engineFluidLeaks').clearValidators();
    this.claimDetailsForm.get('engineFaultCodePresent').clearValidators();
    this.claimDetailsForm.get('engineFaultCodes').clearValidators();
    this.claimDetailsForm.get('engineTearDownConfirmFailure').clearValidators();

    this.claimDetailsForm
      .get('transmissionFluidLevelAndCondition')
      .clearValidators();
    this.claimDetailsForm.get('transmissionFluidLeaks').clearValidators();
    this.claimDetailsForm
      .get('transmissionFaultCodesPresent')
      .clearValidators();
    this.claimDetailsForm.get('transmissionFaultCodes').clearValidators();
    this.claimDetailsForm
      .get('transmissionFactoryOfferInternalParts')
      .clearValidators();
    this.claimDetailsForm
      .get('transmissionTearDownConfirmFailure')
      .clearValidators();
  }

  clearTransmisionValidators() {
    this.claimDetailsForm
      .get('transmissionFluidLevelAndCondition')
      .clearValidators();
    this.claimDetailsForm.get('transmissionFluidLeaks').clearValidators();
    this.claimDetailsForm
      .get('transmissionFaultCodesPresent')
      .clearValidators();
    this.claimDetailsForm.get('transmissionFaultCodes').clearValidators();
    this.claimDetailsForm
      .get('transmissionFactoryOfferInternalParts')
      .clearValidators();
    this.claimDetailsForm
      .get('transmissionTearDownConfirmFailure')
      .clearValidators();

    this.claimDetailsForm
      .get('engineFluidAndCondition')
      .setValidators(Validators.required);
    this.claimDetailsForm
      .get('engineFluidLeaks')
      .setValidators(Validators.required);
    this.claimDetailsForm
      .get('engineFaultCodePresent')
      .setValidators(Validators.required);
    this.claimDetailsForm.get('engineFaultCodes').clearValidators();
    this.claimDetailsForm
      .get('engineTearDownConfirmFailure')
      .setValidators(Validators.required);
  }

  clearEngineValidators() {
    this.claimDetailsForm
      .get('transmissionFluidLevelAndCondition')
      .setValidators(Validators.required);
    this.claimDetailsForm
      .get('transmissionFluidLeaks')
      .setValidators(Validators.required);
    this.claimDetailsForm
      .get('transmissionFaultCodesPresent')
      .setValidators(Validators.required);
    this.claimDetailsForm.get('transmissionFaultCodes').clearValidators();
    this.claimDetailsForm
      .get('transmissionFactoryOfferInternalParts')
      .setValidators(Validators.required);
    this.claimDetailsForm
      .get('transmissionTearDownConfirmFailure')
      .setValidators(Validators.required);

    this.claimDetailsForm.get('engineFluidAndCondition').clearValidators();
    this.claimDetailsForm.get('engineFluidLeaks').clearValidators();
    this.claimDetailsForm.get('engineFaultCodePresent').clearValidators();
    this.claimDetailsForm.get('engineFaultCodes').clearValidators();
    this.claimDetailsForm.get('engineTearDownConfirmFailure').clearValidators();
  }
  revalidateForm() {
    this.claimDetailsForm
      .get('transmissionFluidLevelAndCondition')
      .updateValueAndValidity();
    this.claimDetailsForm
      .get('transmissionFluidLeaks')
      .updateValueAndValidity();
    this.claimDetailsForm
      .get('transmissionFaultCodesPresent')
      .updateValueAndValidity();
    this.claimDetailsForm
      .get('transmissionFaultCodesPresent')
      .updateValueAndValidity();
    this.claimDetailsForm
      .get('transmissionFactoryOfferInternalParts')
      .updateValueAndValidity();
    this.claimDetailsForm
      .get('transmissionTearDownConfirmFailure')
      .updateValueAndValidity();

    this.claimDetailsForm
      .get('engineFluidAndCondition')
      .updateValueAndValidity();
    this.claimDetailsForm.get('engineFluidLeaks').updateValueAndValidity();
    this.claimDetailsForm
      .get('engineFaultCodePresent')
      .updateValueAndValidity();
    this.claimDetailsForm.get('engineFaultCodes').updateValueAndValidity();
    this.claimDetailsForm
      .get('engineTearDownConfirmFailure')
      .updateValueAndValidity();
  }

  goToNextPage() {
    this.validateJobs();
  }
  openJobSummary() {
    this.showJobSummary = true;
  }

  createAnotherClicked() {
    this.createAnother = !this.createAnother;
  }
  createAnotherRentalClicked() {
    this.createAnotherRental = !this.createAnotherRental;
  }
  get f() {
    return this.claimDetailsForm.controls;
  }
  get fRental() {
    return this.rentalRoadsideForm.controls;
  }
  getTotalRental() {
    if (this.rentalRoadsideForm.controls.numberOfRentalDays.errors || this.rentalRoadsideForm.controls.rentalRatePerDay.errors)
      return;
    if (this.rentalRoadsideForm.controls.numberOfRentalDays.value && this.rentalRoadsideForm.controls.rentalRatePerDay.value) {

      const searchCurrency = '$';
      const replaceWith = '';
      const searchComma = ',';
      let amountTmp = this.rentalRoadsideForm.controls.rentalRatePerDay.value
        .split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);
      if (!(isNaN(+amountTmp) || +amountTmp < 0))
        this.rentalRoadsideForm.controls.total.setValue(this.currencyPipe.transform(this.rentalRoadsideForm.controls.numberOfRentalDays.value * amountTmp, '$'));
    }
  }
  submitForm() {
    this.revalidateForm();
    this.submitted = true;

    if (this.noneOfTreadDepthsSelected()) {
      return;
    }

    if (this.isWheelAndTireSelected && this.claimDetailsForm.controls.driverFront.value && !this.claimDetailsForm.controls.driverFrontTreadDepth.value)
      this.claimDetailsForm.controls.driverFrontTreadDepth.setErrors({ requred: true });
    else
      this.claimDetailsForm.controls.driverFrontTreadDepth.setErrors(null);

    if (this.isWheelAndTireSelected && this.claimDetailsForm.controls.passengerFront.value && !this.claimDetailsForm.controls.passengerFrontTreadDepth.value)
      this.claimDetailsForm.controls.passengerFrontTreadDepth.setErrors({ requred: true });
    else
      this.claimDetailsForm.controls.passengerFrontTreadDepth.setErrors(null);

    if (this.isWheelAndTireSelected && this.claimDetailsForm.controls.driverRear.value && !this.claimDetailsForm.controls.driverRearTreadDepth.value)
      this.claimDetailsForm.controls.driverRearTreadDepth.setErrors({ requred: true });
    else
      this.claimDetailsForm.controls.driverRearTreadDepth.setErrors(null);

    if (this.isWheelAndTireSelected && this.claimDetailsForm.controls.passengerRear.value && !this.claimDetailsForm.controls.passengerRearTreadDepth.value)
      this.claimDetailsForm.controls.passengerRearTreadDepth.setErrors({ requred: true });
    else
      this.claimDetailsForm.controls.passengerRearTreadDepth.setErrors(null);




    if (this.isWheelAndTireSelected && (this.tireTaxPriceError))
      this.claimDetailsForm.controls.tireTaxPrice.setErrors({ requred: true });
    else {
      this.claimDetailsForm.controls.tireTaxPrice.setErrors(null);
      this.tireTaxPriceError = false;
    }

    if (this.isWheelAndTireJobSelected) {
      if (!this.claimDetailsForm.controls.causeSelect.value) {
      }
      this.claimDetailsForm.controls.causeDesc.setErrors(null);
    }
    else
      this.claimDetailsForm.controls.causeSelect.setErrors(null);


    if (this.jobComponents?.length == 1 && this.claimDetailsForm.controls.jobComponentIndividual.value) {
      this.claimDetailsForm.controls.jobComponent.setErrors(null);
    }

    if (!this.claimDetailsForm.valid) return;

    this.submitted = false;

    if (this.createJobPressed) return;

    this.createJobPressed = true;

    let jobInfo: Job = {
      jobComponent: this.claimDetailsForm.controls.jobComponent.value?.name,
      jobLossCode: this.claimDetailsForm.controls.model.value
        .lossCode,
      lossDescription: this.claimDetailsForm.controls.model.value.partDesc,
      customerComplaintDesc: this.claimDetailsForm.controls
        .customerComplaintDesc.value,
      causeDesc: this.claimDetailsForm.controls.causeDesc.value,
      correctionDesc: this.claimDetailsForm.controls.correctionDesc.value,

    };
    if (this.jobComponents?.length == 1 && this.claimDetailsForm.controls.jobComponentIndividual.value) {
      jobInfo.jobComponent = this.claimDetailsForm.controls.jobComponentIndividual.value;
    }
    if (this.editJobBoolean) {
      jobInfo.id = this.editJobId;
    }
    jobInfo.transmissionFluidAndCondition = null;
    jobInfo.hasTransmissionFluidLeaks = null;
    jobInfo.isTransmissionFaultCodesPresent = null;

    jobInfo.transmissionFaultCodes = null;
    jobInfo.transmissionOfferStatus = null;
    jobInfo.transmissionTearDownToConfirmTheFailure = null;
    jobInfo.anyTransmissionFluidLeaks = null;
    jobInfo.engineCoolantAndOilStatus = null;
    jobInfo.hasEngineFluidLeaks = null;
    jobInfo.isEngineFaultCodesPresent = null;
    jobInfo.engineFaultCodes = null;
    jobInfo.anyEngineFluidLeaks = null;
    jobInfo.engineTearDownToConfirmTheFailure = null;
    jobInfo.treadDepth = null;

    jobInfo.driverFront = null;
    jobInfo.passengerFront = null;
    jobInfo.driverRear = null;
    jobInfo.passengerRear = null;


    if (this.isEngineSelected) {
      jobInfo.engineCoolantAndOilStatus = this.claimDetailsForm.controls.engineFluidAndCondition.value;
      (jobInfo.hasEngineFluidLeaks = false),
        (jobInfo.isEngineFaultCodesPresent =
          this.claimDetailsForm.controls.engineFaultCodePresent.value ==
          'true');
      jobInfo.engineFaultCodes = this.claimDetailsForm.controls.engineFaultCodes.value;
      jobInfo.anyEngineFluidLeaks = this.claimDetailsForm.controls.engineFluidLeaks.value;

      jobInfo.engineTearDownToConfirmTheFailure = this.claimDetailsForm.controls.engineTearDownConfirmFailure.value;
    } else if (this.isTransmissionSelected) {
      jobInfo.transmissionFluidAndCondition = this.claimDetailsForm.controls.transmissionFluidLevelAndCondition.value;
      (jobInfo.hasTransmissionFluidLeaks = false),
        (jobInfo.isTransmissionFaultCodesPresent =
          this.claimDetailsForm.controls.transmissionFaultCodesPresent.value ==
          'true');
      jobInfo.transmissionFaultCodes = this.claimDetailsForm.controls.transmissionFaultCodes.value;
      jobInfo.transmissionOfferStatus = this.claimDetailsForm.controls.transmissionFactoryOfferInternalParts.value;
      jobInfo.transmissionTearDownToConfirmTheFailure = this.claimDetailsForm.controls.transmissionTearDownConfirmFailure.value;
      jobInfo.anyTransmissionFluidLeaks = this.claimDetailsForm.controls.transmissionFluidLeaks.value;

    } else if (this.isWheelAndTireSelected) {

      jobInfo.driverFront = this.claimDetailsForm.controls.driverFront.value;
      jobInfo.passengerFront = this.claimDetailsForm.controls.passengerFront.value;
      jobInfo.driverRear = this.claimDetailsForm.controls.driverRear.value;
      jobInfo.passengerRear = this.claimDetailsForm.controls.passengerRear.value;

      if (this.claimDetailsForm.controls.driverFront.value) {
        jobInfo.driverFrontTreadDepth = this.claimDetailsForm.controls.driverFrontTreadDepth.value;
      }
      if (this.claimDetailsForm.controls.passengerFront.value) {
        jobInfo.passengerFrontTreadDepth = this.claimDetailsForm.controls.passengerFrontTreadDepth.value;
      }
      if (this.claimDetailsForm.controls.driverRear.value) {
        jobInfo.driverRearTreadDepth = this.claimDetailsForm.controls.driverRearTreadDepth.value;
      }
      if (this.claimDetailsForm.controls.passengerRear.value) {
        jobInfo.passengerRearTreadDepth = this.claimDetailsForm.controls.passengerRearTreadDepth.value;
      }

      if (this.claimDetailsForm.controls.tireTaxPrice.value) {
        let amountTmp = this.claimDetailsForm.controls.tireTaxPrice.value
          .split(this.searchCurrency)
          .join(this.replaceWith)
          .split(this.searchComma)
          .join(this.replaceWith);
        jobInfo.tax = +amountTmp;
      }
    } else if (!this.isWheelAndTireSelected) {
      jobInfo.treadDepth = null;
      jobInfo.tax = null;
      jobInfo.driverFront = null;
      jobInfo.passengerFront = null;
      jobInfo.driverRear = null;
      jobInfo.passengerRear = null;

      jobInfo.driverFrontTreadDepth = null;
      jobInfo.passengerFrontTreadDepth = null;
      jobInfo.driverRearTreadDepth = null;
      jobInfo.passengerRearTreadDepth = null;

    }
    if (this.isWheelAndTireJobSelected) {
      jobInfo.causeDesc = this.claimDetailsForm.controls.causeSelect.value;
    }

    jobInfo.isEngineClaim = this.isEngineSelected;
    jobInfo.isTransmissionClaim = this.isTransmissionSelected;
    if (!this.editJobBoolean)
      this.claimService.addJob(this.claim.id, jobInfo).subscribe(
        (result: any) => {
          this.toastr.success('Successfully added job', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          this.getClaim(this.claim.id);
          this.claimService.job.next(result);
          this.noJobsAdded = false;

          document.getElementById('closeModalButton').click();
          this.claimDetailsForm.reset();
          this.openJobSummary();
          if (this.createAnother)
            document.getElementById('openModalButton').click();
          this.createAnother = false;
          this.createJobPressed = false;

        },
        (error) => {
          console.log(error);
          this.createJobPressed = false;
          if (error.status && error.status == 403) {
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else {
            this.toastr.error(
              'Something went wrong while adding job, please try again. Error code: ' + error.status,
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          }
        }
      );
    else {
      jobInfo.claimId = this.claim.id;
      this.claimService.updateJob(jobInfo).subscribe(
        (result: any) => {

          this.getClaim(this.claim.id);
          this.toastr.success('Successfully updated job', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          this.claimService.job.next(result);
          this.noJobsAdded = false;

          document.getElementById('closeModalButton').click();
          this.claimDetailsForm.reset();
          this.openJobSummary();
          if (this.createAnother)
            document.getElementById('openModalButton').click();
          this.createAnother = false;
          this.editJobBoolean = false;
          this.createJobPressed = false;

        },
        (error) => {
          console.log(error);
          this.createJobPressed = false;
          if (error.status && error.status == 403) {
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else {
            this.toastr.error(
              'Something went wrong while updating job, please try again. Error code: ' + error.status,
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          }
        }
      );
    }

  }

  noneOfTreadDepthsSelected() {
    return this.isWheelAndTireSelected && !this.claimDetailsForm.controls.driverFront.value &&
      !this.claimDetailsForm.controls.passengerFront.value && !this.claimDetailsForm.controls.driverRear.value && !this.claimDetailsForm.controls.passengerRear.value;
  }

  getClaim(claimId: string) {
    this.claimService.getClaimById(claimId).subscribe(
      (result: any) => {
        this.claimService.claim.next(result);
      }
    );
  }
  submitRentalRoadsideForm() {
    this.submittedRental = true;

    if (this.showRental) {
      if (this.rentalRatePerDayError)
        return;

      if (!this.showRentalDesc)
        this.rentalRoadsideForm.controls.rentalDesc.setErrors(null);

      this.rentalRoadsideForm.controls.roadsideAmount.setErrors(null);
      this.rentalRoadsideForm.controls.roadsideDesc.setErrors(null);
      this.rentalRoadsideForm.controls.roadsideDescChoice.setErrors(null);
    }

    if (this.showRoadSide) {
      if (this.roadSideAmountError)
        return;

      if (!this.showRoadSideDesc)
        this.rentalRoadsideForm.controls.roadsideDesc.setErrors(null);

      this.rentalRoadsideForm.controls.rentalDesc.setErrors(null);
      this.rentalRoadsideForm.controls.rentalDescChoice.setErrors(null);
      this.rentalRoadsideForm.controls.rentalRatePerDay.setErrors(null);
      this.rentalRoadsideForm.controls.numberOfRentalDays.setErrors(null);
    }
    if (this.hideRentalQuestion) {
      this.rentalRoadsideForm.controls.roadsideDescChoice.setErrors(null);
      this.rentalRoadsideForm.controls.rentalDescChoice.setErrors(null);
    }
    if (this.rentalRoadsideForm.controls.jobLossCodeDescRental.value == "Select") {
      this.rentalRoadsideForm.controls.jobLossCodeDescRental.setErrors({ required: true });
    }
    if (this.jobComponentsBenefit.length == 1 && this.rentalRoadsideForm.controls.jobComponentRentalIndividual.value) {
      this.rentalRoadsideForm.controls.jobComponentRental.setErrors(null);
    }
    if (!this.rentalRoadsideForm.valid) return;

    if (this.createRentalPressed) return;

    this.createRentalPressed = true;

    this.submittedRental = false;
    const searchCurrency = '$';
    const replaceWith = '';
    const searchComma = ',';

    let jobInfo: Job = {
      jobComponent: this.rentalRoadsideForm.controls.jobComponentRental?.value?.name,
      jobLossCode: this.rentalRoadsideForm.controls.jobLossCodeDescRental.value
        .lossCode,
      lossDescription: this.rentalRoadsideForm.controls.jobLossCodeDescRental.value?.partDesc,
      type: this.BENEFIT
    };
    jobInfo.isRentalOnly = this.showRentalDesc;
    jobInfo.isAssistanceOnly = this.showRoadSideDesc;
    if (this.jobComponentsBenefit.length == 1 && this.rentalRoadsideForm.controls.jobComponentRentalIndividual.value && !jobInfo.jobComponent) {
      jobInfo.jobComponent = this.rentalRoadsideForm.controls.jobComponentRentalIndividual.value;
    }

    if (this.showRental) {
      let amountTmp = this.rentalRoadsideForm.controls.rentalRatePerDay.value
        .split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);
      jobInfo.price = +amountTmp;
      jobInfo.quantity = this.rentalRoadsideForm.controls.numberOfRentalDays.value;
      jobInfo.total = +amountTmp * jobInfo.quantity;
      jobInfo.quantity = this.rentalRoadsideForm.controls.numberOfRentalDays.value;

      if (this.showRentalDesc) {
        jobInfo.description = this.rentalRoadsideForm.controls.rentalDesc.value;
      } else jobInfo.description = "";
    } else if (this.showRoadSide) {
      let amountTmp = this.rentalRoadsideForm.controls.roadsideAmount.value
        .split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);
      jobInfo.price = +amountTmp;
      jobInfo.total = 0;
      jobInfo.quantity = 0;
      if (this.showRoadSideDesc) {
        jobInfo.description = this.rentalRoadsideForm.controls.roadsideDesc.value;
      } else jobInfo.description = "";
    }
    if (this.editJobBoolean) {
      jobInfo.id = this.editJobId;
      jobInfo.claimId = this.claim.id;
    }
    if (!this.editJobBoolean)
      this.claimService.addJob(this.claim.id, jobInfo).subscribe(
        (result: any) => {
          this.getClaim(this.claim.id);
          this.toastr.success('Successfully added job', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          this.claimService.job.next(result);
          this.noJobsAdded = false;

          document.getElementById('closeModalRentalButton').click();
          this.rentalRoadsideForm.reset();

          this.openJobSummary();

          if (this.createAnotherRental)
            document.getElementById('openRentalModalButton').click();
          this.createAnotherRental = false;
          this.claimService.getClaimById(this.claim.id).subscribe(
            (result: any) => {
              this.claimService.claim.next(result);
            }
          );
          this.createRentalPressed = false;

        },
        (error) => {
          console.log(error);
          this.createRentalPressed = false;
          if (error.status && error.status == 403) {
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else {
            this.toastr.error(
              'Something went wrong while adding job, please try again. Error code: ' + error.status,
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          }
        }
      ); else
      this.claimService.updateJob(jobInfo).subscribe(
        (result: any) => {
          this.getClaim(this.claim.id);
          this.toastr.success('Successfully updated job', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          this.claimService.job.next(result);
          this.noJobsAdded = false;

          document.getElementById('closeModalRentalButton').click();
          this.rentalRoadsideForm.reset();

          this.openJobSummary();
          if (this.createAnotherRental)
            document.getElementById('openRentalModalButton').click();
          this.createAnotherRental = false;
          this.editJobBoolean = false;
          this.claimService.getClaimById(this.claim.id).subscribe(
            (result: any) => {
              this.claimService.claim.next(result);
            }
          );
          this.createRentalPressed = false;

        },
        (error) => {
          console.log(error);
          this.createRentalPressed = false;
          if (error.status && error.status == 403) {
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else {
            this.toastr.error(
              'Something went wrong while updating job, please try again. Error code: ' + error.status,
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          }
        }
      );

  }
  updateStep(stepNumber: number, showToastr: boolean) {
    if (this.stepUpdated) return;
    let claimObj: Claim = {
      step: stepNumber,
      id: this.claim.id,
    };
    let tmpStepNumber = this.claimService.currentStep;
    this.claimService.currentStep = stepNumber;
    this.claimService.updateClaim(claimObj).subscribe(
      () => {
        if (showToastr) {
          this.toastr.success('Successfully Completed Step 2. Job Summary', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          this.stepUpdated = true;
        }
      },
      (error) => {
        console.log(error);
        if (showToastr) {
          this.claimService.currentStep = tmpStepNumber;
          if (error.status && error.status == 403) {
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else {
            this.toastr.error('Something went wrong while updating claim, please try again. ' + 'Error code: ' + error.status, 'Create claim', {
              positionClass: 'toast-top-center', timeOut: 10000
            });
          }
        }
      }
    );
  }
  getCodeListForComponent(component, type) {
    if (!component)
      return;



    if (type == this.OTHER)
      this.claimDetailsForm.controls['model'].setValue(null);
    else if (type == this.BENEFIT)
      this.rentalRoadsideForm.controls['jobLossCodeDescRental'].setValue("Select");

    let componentId = component?.id;
    if (!componentId) {
      let jobComps;
      if (type == this.OTHER) {
        jobComps = this.jobComponents;
      } else if (type == this.BENEFIT) {
        jobComps = this.jobComponentsBenefit;
      }

      for (let i = 0; i < jobComps?.length && !componentId; i++) {
        let jobComp: any = jobComps[i];
        if (jobComp?.name == component)
          componentId = jobComp.id;
      }

    }
    this.codes = [];
    this.codesBenefit = [];

    if (componentId)
      this.claimService.getCodeListForComponent(componentId, this.claim.planId).subscribe(
        (res: any) => {
          if (type == this.OTHER)
            this.codes = [];
          if (type == this.BENEFIT)
            this.codesBenefit = [];

          for (let i = 0; i < res.length; i++) {
            if (type == this.OTHER) {
              if (this.editJobBoolean && res[i].lossCode == this.editJobLossCodeObject) {
                this.claimDetailsForm.controls['model'].setValue(res[i]);
              }
              this.codes.push(res[i]);
            } else if (type == this.BENEFIT) {
              if (this.editJobBoolean && res[i].lossCode == this.editJobLossCodeObject) {
                this.rentalRoadsideForm.controls['jobLossCodeDescRental'].setValue(res[i]);
              }
              this.codesBenefit.push(res[i]);
            }
          }
          if (type == this.OTHER) {
            this.claimDetailsForm.updateValueAndValidity();
          }

        },
        (error) => {
          console.log(error);
          this.toastr.error('Something went wrong while fetching job loss codes, please try again. ' + 'Error code: ' + error.status, 'Create claim', {
            positionClass: 'toast-top-center', timeOut: 12000
          });
        }
      );
  }
  getJobComponents(jobType: string, planId: number) {

    this.claimService.getJobComponents(jobType, planId).subscribe(
      (res: any) => {

        if (res && res.length > 0) {
          if (jobType == "OTHER") {
            this.jobComponents = [];
            this.disableJobComponentField = false;
            this.componentsOtherSubscription?.unsubscribe();
          } else if (jobType == "BENEFIT") {
            this.jobComponentsBenefit = [];
            this.disableJobComponentBenefitField = false;
            this.componentsBenefitSubscription?.unsubscribe();
          }
        }
        for (let i = 0; i < res.length; i++) {
          if (jobType == "OTHER") {
            if (!res[i].name?.startsWith("G:  GAP") && !res[i].name?.startsWith("V:  Vehicle Maintenance") && !res[i].name?.startsWith("X:  Expenses")
              && !res[i].name?.startsWith("ZCC:  Credit Card Reconciliation") && !res[i].name?.startsWith("ZG:  Security Guard")
              && !res[i].name?.startsWith("ZTPA:  Third Party Claims"))
              this.jobComponents.push(res[i]);

            this.claimDetailsForm.updateValueAndValidity();
          } else if (jobType == "BENEFIT") {
            this.jobComponentsBenefit.push(res[i]);
          }
        }
        if (this.jobComponentsBenefit.length == 1) {
          let jobComponentName: any = this.jobComponentsBenefit[0];
          this.rentalRoadsideForm.controls.jobComponentRentalIndividual?.setValue(jobComponentName.name);
          this.disableJobComponentBenefitField = true;
        }
        if (this.jobComponents?.length == 1) {
          let jobComponentName: any = this.jobComponents[0];
          this.claimDetailsForm.controls.jobComponentIndividual?.setValue(jobComponentName?.name);
          if (jobComponentName && jobComponentName.name && jobComponentName.name.includes('Tire') && jobComponentName.name.includes('Wheel')) {
            this.isTransmissionSelected = false;
            this.isEngineSelected = false;
            this.isWheelAndTireJobSelected = true;
            this.clearValidators();
          }


          this.disableJobComponentField = true;
        }
      },
      (error) => {
        console.log(error);
        if (jobType == "OTHER") {
          this.componentsOtherSubscription?.unsubscribe();
        } else if (jobType == "BENEFIT") {
          this.componentsBenefitSubscription?.unsubscribe();
        }
        this.toastr.error('Something went wrong while fetching job components, please try again. ' + 'Error code: ' + error.status, 'Create claim', {
          positionClass: 'toast-top-center', timeOut: 12000
        });
      }
    );
  }
  resetForm() {
    this.editJobBoolean = false;
    this.submitted = false;
    this.disableJobComponentField = false;

    this.clearValidators();
    this.claimDetailsForm.reset();
    if (this.jobComponents?.length == 1 && !this.disabledField) {
      let jobComponentName: any = this.jobComponents[0];

      this.claimDetailsForm.controls.jobComponentIndividual?.setValue(jobComponentName.name);

      this.disableJobComponentField = true;
    }
    this.claimDetailsForm.controls.customerComplaintDesc.setValue("");
    this.claimDetailsForm.controls.causeDesc.setValue("");
    this.claimDetailsForm.controls.correctionDesc.setValue("");
  }
  resetRentalForm() {
    this.submittedRental = false;
    this.editJobBoolean = false;
    this.disableJobComponentBenefitField = false;

    this.rentalRoadsideForm.reset();
    this.formattedAmount = '';
    this.formattedRentalRatePerDay = '';
    if (this.jobComponentsBenefit.length == 1 && !this.disabledField) {
      this.disableJobComponentBenefitField = true;
      let jobComponentName: any = this.jobComponentsBenefit[0];

      this.rentalRoadsideForm.controls.jobComponentRentalIndividual?.setValue(jobComponentName.name);
    }
    this.showRental = false;
    this.showRoadSide = false;
    this.showRentalDesc = false;
    this.showRoadSideDesc = false;
    this.showMaxAmountError = false;
    this.rentalRatePerDayError = false;
    this.roadSideAmountError = false;
  }
  validateJobs() {
    this.pressedContinue = true;
    this.claimService.getJobs(this.claim.id).subscribe(
      (result: any) => {
        this.jobList = result;
        if (this.jobList && this.jobList.length > 0) {
          for (let i = 0; i < this.jobList.length; i++) {
            if (this.jobList[i].parts?.length <= 0 && this.jobList[i].labors?.length <= 0 && this.jobList[i].sublets?.length <= 0) {
              this.setToastrInvalidJob(this.jobList[i].jobNumber);
              this.showJobSummary = true;
              this.updateStep(1, false);
              this.pressedContinue = false;
              return;
            }
          }
          if (!this.claimService.previewActiveClaim) {
            this.updateStep(2, true);
            this.messageEvent.emit(true);
          } else {
            this.claimService.currentStep = 2;
            this.messageEvent.emit(true);
          }

        } else {
          this.noJobsAdded = true;
          this.showJobSummary = false;
          this.pressedContinue = false;
          return false;
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error(
          'Something went wrong while fetching jobs, please try again. ' + 'Error code: ' + error.code,
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
        this.pressedContinue = false;
        return false;
      }
    );
  }

  setToastrInvalidJob(jobNumber: string) {
    this.toastr.error(
      'Job ' + jobNumber + ' has to have at least one part, labor or sublet record.',
      'Create claim',
      {
        positionClass: 'toast-top-center',
      });
  }

  transformAmount(element, formName) {
    this.rentalRatePerDayError = false;
    this.roadSideAmountError = false;
    this.tireTaxPriceError = false;
    this.showMaxAmountError = false;

    const searchCurrency = '$';
    const replaceWith = '';
    const searchComma = ',';

    let amountTmp;
    let maxAmountRental = this.rentalRoadsideForm.controls.jobLossCodeDescRental?.value?.maxPrice;
    if (formName == 'rental')
      amountTmp = this.formattedRentalRatePerDay
        ?.split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);
    else if (formName == 'roadside')
      amountTmp = this.formattedAmount
        ?.split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);
    else if (formName == 'tireTax')
      amountTmp = this.formattedTireTaxPrice
        ?.split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);

    if ((isNaN(+amountTmp) || +amountTmp <= 0)) {
      if (formName == 'rental') {
        this.rentalRatePerDayError = true;
        return;
      }
      else if (formName == 'roadside') {
        this.roadSideAmountError = true;
        return;
      } else if (formName == 'tireTax' && +amountTmp < 0) {
        if (amountTmp != "")
          this.tireTaxPriceError = true;
        return;
      }

    }
    if (formName == 'tireTax' && +amountTmp > 5) {
      this.tireTaxPriceError = true;
      return;
    }
    if ((formName == 'roadside' || formName == 'rental') && maxAmountRental && +amountTmp > maxAmountRental) {
      this.showMaxAmountError = true;
      if (formName == 'rental') {
        this.rentalRatePerDayError = true;
      }
      if (formName == 'roadside') {
        this.roadSideAmountError = true;
      }
      return;
    }
    this.showMaxAmountError = false;
    this.rentalRatePerDayError = false;
    this.tireTaxPriceError = false;
    let amount = this.currencyPipe.transform(amountTmp, '$');
    let amountSplitted = amount.split(".");
    if ((amountSplitted?.length == 2 && (amountSplitted[0]?.length > 4 || amountSplitted[1]?.length > 2))) {
      if (formName == 'rental') {
        this.rentalRatePerDayError = true;
      }
      else if (formName == 'roadside') {
        this.roadSideAmountError = true;
      } else if (formName == 'tireTax') {
        this.tireTaxPriceError = true;
      }
      return;
    }
    if (formName == 'rental') {
      this.formattedRentalRatePerDay = amount;
      this.getTotalRental();
    }
    else if (formName == 'roadside') {
      this.formattedAmount = amount;
    } else if (formName == 'tireTax') {
      this.formattedTireTaxPrice = amount;
    }

    element.target.value = amount;
  }
  deleteClaim($event) {
    if ($event) {
      this.claimService.deleteClaim(this.claimService.claim.value.id).subscribe(() => {
        document.getElementById("closeConfirmActionButton-1").click();
        this.toastr.success('Successfully deleted claim', '', {
          positionClass: 'toast-top-center',
        });
        this.router.navigate(['']);
      }, (error) => {
        console.log(error);
        if (error.status && error.status == 403) {
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else {
          this.toastr.error(
            'Something went wrong while deleting claim, please try again. ' + 'Error code: ' + error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      })
    }
  }
  setClaimDelete() {
    this.message = "Are you sure you want to delete this claim?";
  }
  editJob($event: Job) {
    let tmpJob: Job = $event;
    if (!tmpJob)
      return;

    this.editJobId = tmpJob.id;
    if (tmpJob.type == this.BENEFIT) {
      document.getElementById('openRentalModalButton').click();

      this.editJobBoolean = true;
      let tmpJobComponent = this.getJobComponentObject(tmpJob.jobComponent, this.jobComponentsBenefit);
      if (tmpJobComponent) {
        this.rentalRoadsideForm.controls.jobComponentRental.setValue(tmpJobComponent);
        if (this.jobComponentsBenefit.length == 1) {
          this.rentalRoadsideForm.controls.jobComponentRentalIndividual.setValue(tmpJobComponent.name);
          this.rentalRoadsideForm.controls.jobComponentRental.setErrors(null);
        }
      }
      this.editJobLossCodeObject = tmpJob.jobLossCode;

      this.showRental = tmpJob.isRentalOnly;
      this.showRoadSide = tmpJob.isAssistanceOnly;
      if (this.showRental || tmpJob.quantity != 0) {

        this.rentalRoadsideForm.controls.rentalRatePerDay.setValue(this.currencyPipe.transform(tmpJob.price, '$'));
        this.formattedRentalRatePerDay = this.currencyPipe.transform(tmpJob.price, '$');
        this.formattedAmount = '';
        this.rentalRoadsideForm.controls.numberOfRentalDays.setValue(tmpJob.quantity);
        this.rentalRoadsideForm.controls.total.setValue(this.currencyPipe.transform(tmpJob.total, '$'));

        this.rentalRoadsideForm.controls.numberOfRentalDays.setValue(tmpJob.quantity);


        this.rentalRoadsideForm.controls.rentalDesc.setValue(tmpJob.description);
        this.showRentalDesc = tmpJob.description ? true : false;
        this.rentalRoadsideForm.controls.rentalDescChoice.setValue(tmpJob.description ? "true" : "false");
      } else if (this.showRoadSide || tmpJob.quantity == 0) {
        this.formattedRentalRatePerDay = '';
        this.showRoadSideDesc = tmpJob.description ? true : false;
        this.rentalRoadsideForm.controls.roadsideAmount.setValue(this.currencyPipe.transform(tmpJob.price, '$'));
        this.formattedAmount = this.currencyPipe.transform(tmpJob.price, '$');
        this.rentalRoadsideForm.controls.roadsideDesc.setValue(tmpJob.description);
        this.rentalRoadsideForm.controls.roadsideDescChoice.setValue(tmpJob.description ? "true" : "false");
      }
    }
    else if (tmpJob.type == this.OTHER) {
      document.getElementById('openModalButton').click();
      this.editJobBoolean = true;
      let tmpJobComponent = this.getJobComponentObject(tmpJob.jobComponent, this.jobComponents);
      if (tmpJobComponent)
        this.claimDetailsForm.controls.jobComponent.setValue(tmpJobComponent);

      this.editJobLossCodeObject = tmpJob.jobLossCode;
      this.claimDetailsForm.controls.customerComplaintDesc.setValue(tmpJob.customerComplaintDesc);
      this.claimDetailsForm.controls.causeDesc.setValue(tmpJob.causeDesc);
      this.claimDetailsForm.controls.correctionDesc.setValue(tmpJob.correctionDesc);


      this.claimDetailsForm.controls.engineFluidAndCondition?.setValue(tmpJob.engineCoolantAndOilStatus);
      this.claimDetailsForm.controls.engineFaultCodePresent?.setValue(tmpJob.isEngineFaultCodesPresent);

      this.claimDetailsForm.controls.engineFaultCodes?.setValue(tmpJob.engineFaultCodes);
      this.claimDetailsForm.controls.engineFluidLeaks?.setValue(tmpJob.anyEngineFluidLeaks);

      this.claimDetailsForm.controls.engineTearDownConfirmFailure?.setValue(tmpJob.engineTearDownToConfirmTheFailure);

      this.claimDetailsForm.controls.transmissionFluidLevelAndCondition?.setValue(tmpJob.transmissionFluidAndCondition);
      this.claimDetailsForm.controls.transmissionFaultCodesPresent?.setValue(tmpJob.isTransmissionFaultCodesPresent);
      this.claimDetailsForm.controls.transmissionFaultCodes?.setValue(tmpJob.transmissionFaultCodes);
      this.claimDetailsForm.controls.transmissionFactoryOfferInternalParts?.setValue(tmpJob.transmissionOfferStatus);
      this.claimDetailsForm.controls.transmissionTearDownConfirmFailure?.setValue(tmpJob.transmissionTearDownToConfirmTheFailure);
      this.claimDetailsForm.controls.transmissionFluidLeaks?.setValue(tmpJob.anyTransmissionFluidLeaks);

      this.claimDetailsForm.controls.treadDepth?.setValue(tmpJob.treadDepth);

      this.claimDetailsForm.controls.driverFront?.setValue(tmpJob.driverFront);
      this.claimDetailsForm.controls.passengerFront?.setValue(tmpJob.passengerFront);
      this.claimDetailsForm.controls.driverRear?.setValue(tmpJob.driverRear);
      this.claimDetailsForm.controls.passengerRear?.setValue(tmpJob.passengerRear);

      if (tmpJob.driverFront) {
        this.claimDetailsForm.controls.driverFrontTreadDepth?.setValue(tmpJob.driverFrontTreadDepth);
      }
      if (tmpJob.passengerFront) {
        this.claimDetailsForm.controls.passengerFrontTreadDepth?.setValue(tmpJob.passengerFrontTreadDepth);
      }
      if (tmpJob.driverRear) {
        this.claimDetailsForm.controls.driverRearTreadDepth?.setValue(tmpJob.driverRearTreadDepth);
      }
      if (tmpJob.passengerRear) {
        this.claimDetailsForm.controls.passengerRearTreadDepth?.setValue(tmpJob.passengerRearTreadDepth);
      }

      if (tmpJob.tax) {
        this.claimDetailsForm.controls.tireTaxPrice.setValue(this.currencyPipe.transform(tmpJob.tax, '$'));
        this.formattedTireTaxPrice = this.currencyPipe.transform(tmpJob.tax, '$');
      }
      this.claimDetailsForm.controls.causeSelect?.setValue(tmpJob.causeDesc);

      this.isEngineSelected = tmpJob.isEngineClaim;
      this.isTransmissionSelected = tmpJob.isTransmissionClaim;

    }
  }

  getJobComponentObject(jobComponentName: string, jobComponents: any[]) {
    for (let i = 0; i < jobComponents?.length; i++) {
      if (jobComponents[i].name == jobComponentName)
        return jobComponents[i];
    }
    return null;
  }
  public model: any;

  formatter = (result: any) => result.partDesc;


  focus$ = new Subject<string>();
  setStyle() {
    setTimeout(() => {
      if (document.getElementsByTagName('ngb-typeahead-window')[0]) {
        (document.getElementsByTagName('ngb-typeahead-window')[0] as HTMLElement).style.maxHeight = '500px';
        (document.getElementsByTagName('ngb-typeahead-window')[0] as HTMLElement).style.overflowY = 'scroll';
      }
    }, 300);

  }
  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(0), distinctUntilChanged());
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term: any) => (term === '' ? this.codes
        : this.codes.filter(v => v?.partDesc.toLowerCase().indexOf(term.toLowerCase()) > -1)))
    );
  }
  itemSelected($event) {
    console.log($event);
  }
  saveAsDraft() {
    this.toastr.success(
      'Successfully saved claim as draft',
      'Create claim',
      {
        positionClass: 'toast-top-center',
      }
    );
    this.claimService.unlockClaim(this.claim.id).subscribe(
      () => {
        this.toastr.success('Successfully unlocked claim', '', {
          positionClass: 'toast-top-center',
        });
      },
      (error) => {
        this.showSpinnerDraft = false;
        console.log(error);
        this.toastr.error(
          'Something went wrong while unlocking claim, please try again. ' + 'Error code: ' + error.status,
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
    return;
  }
}
