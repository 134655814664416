<div id="confirmActionModal{{indexNum}}" class="modal">
    <div class="modal-dialog modal-lg modal-width" role="document">
        <form fxLayout="column" [formGroup]="confirmActionForm" (ngSubmit)="confirmActionFormSubmit()">
            <div class="modal-content tx-size-sm">
                <div class="modal-header pd-x-20">
                    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                        {{header}}
                    </h6>
                    <button [ngClass]="{ 'hideButton' : hideCloseButton }" type="button" class="close"
                        aria-label="Close" id="closeconfirmActionModalButton" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="row">
                    <div class="col-md">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-12">
                                        <label class="form-control-label">{{message}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-oblong btn-secondary btn" data-dismiss="modal"
                        id="closeConfirmActionButton{{indexNum}}" (click)="cancel()">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-oblong btn-primary btn">
                        {{buttonMessage}}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>