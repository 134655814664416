<div>
    <form fxLayout="column" [formGroup]="claimComplaintForm" (ngSubmit)="submitForm()">
        <div class="row pd-t-20 pd-l-50 mg-b-5">
            <div class="col-3">
                <div class="form-group">
                    <label class="form-control-label">Repair Order Number <span class="tx-danger">*</span></label>
                    <input class="form-control" value="" [formControlName]="'repairOrderNumber'" [ngClass]="{
              'is-invalid': submitted && f.repairOrderNumber.errors
            }" required />
                </div>
            </div>
        </div>
        <h5 class="tx-black pd-t-20">Claim Complaints</h5>

        <div class="row pd-t-20 pd-l-50 mg-b-5">
            <div class="col-11">
                <div class="form-group">
                    <label class="form-control-label">Customer Complaint <span class="tx-danger">*</span></label>
                    <textarea rows="3" class="form-control"
                        placeholder="I've experienced an issue with the car's HVAC. The car heater blowing cold air"
                        [formControlName]="'customerComplaintDesc'" [ngClass]="{
              'is-invalid': submitted && f.customerComplaintDesc.errors
            }"></textarea>
                </div>
            </div>
        </div>
        <div class="row pd-l-50 mg-b-5">
            <div class="col-11">
                <div class="form-group">
                    <label class="form-control-label">Cause</label>
                    <textarea rows="3" class="form-control" placeholder="Type cause here"
                        [formControlName]="'causeDesc'"></textarea>
                </div>
            </div>
        </div>
        <div class="row pd-l-50 mg-b-5">
            <div class="col-11">
                <div class="form-group">
                    <label class="form-control-label">Correction</label>
                    <textarea rows="3" class="form-control" placeholder="Type correction here"
                        [formControlName]="'correctionDesc'"></textarea>
                </div>
            </div>
        </div>
        <div class="row pd-l-50 mg-b-5">
            <div class="col-lg-4">
                <div class="form-group mg-b-10-force">
                    <label class="form-control-label">Was car towed in? <span class="tx-danger">*</span></label>
                    <select class="form-control select2" data-placeholder="Select"
                        (change)="selectYesNo(selectField1.value, '1')" #selectField1
                        [ngClass]="{ 'is-invalid': submitted && f.isCarTowedIn.errors }"
                        [formControlName]="'isCarTowedIn'">
                        <option label="Select" [selected]="true" [hidden]="true">
                            Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                    <span class="tx-info" *ngIf="selectedQuestion1">Please attach applicable tow bill if requesting
                        reimbursement</span>

                    <div class="card pd-20 col-12" *ngIf="selectedQuestion1">

                        <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" class="custom-file pd-b-90">
                            <div>
                                <input [formControlName]="'uploadFile'" type="file" name="profile"
                                    (change)="onFileSelect($event)" />
                            </div>
                            <div class="mg-t-10">
                                <button type="submit" class="btn-medium btn-primary btn-oblong">
                                    Upload
                                </button>
                            </div>
                        </form>
                        <label class="col-12 control-label"> Uploaded files </label>

                        <ul class="list-group col-12 pd-5 justify-content-around">
                            <li *ngFor="let attachment of attachments; let i = index" class="row">
                                <div class="col-8">{{ attachment.filename }}</div>
                                <button class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold col-2"
                                    (click)="deleteAttachment(attachment)" type="button">
                                    <i class=" ion-trash-a mg-r-5"></i>
                                </button>
                                <button class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold col-2"
                                    (click)="downloadAttachment(attachment)" type="button">
                                    <i class="fa fa-download mg-r-5"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="form-group mg-b-10-force">
                    <label class="form-control-label">Any modifications? <span class="tx-danger">*</span></label>
                    <select class="form-control select2" data-placeholder="Select"
                        (change)="selectYesNo(selectField2.value, '2')" #selectField2
                        [formControlName]="'anyModificationsBoolean'" [ngClass]="{
              'is-invalid': submitted && f.anyModificationsBoolean.errors
            }">
                        <option label="Select" [selected]="true" [hidden]="true">
                            Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div class="form-group" *ngIf="selectedQuestion2">
                    <label class="form-control-label">Please provide a brief description of the modification(s)
                        <span class="tx-danger">*</span></label>
                    <textarea class="form-control" type="text" name="modificationDesc" value="" placeholder=""
                        [formControlName]="'modificationsDesc'" [ngClass]="{
              'is-invalid':
                submitted && selectedQuestion2 && f.modificationsDesc.errors
            }"></textarea>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group mg-b-10-force">
                    <label class="form-control-label">Any commercial use, delivery use or ride share use? I.E. Uber or
                        Lyft vehicle?<span class="tx-danger">*</span>
                    </label>
                    <select class="form-control select" data-placeholder="Select"
                        (change)="selectYesNo(selectField3.value, '3')" #selectField3
                        [formControlName]="'commercialUse'"
                        [ngClass]="{ 'is-invalid': submitted && f.commercialUse.errors }">
                        <option label="Select" [selected]="true" [hidden]="true">
                            Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div class="form-group" *ngIf="selectedQuestion3">
                    <label class="form-control-label tx-info">Please provide a brief description of the commercial,
                        delivery or
                        ride share use. Attach any relevant photos
                        <span class="tx-danger">*</span></label>
                    <textarea class="form-control" type="text" name="modificationDesc" value="" placeholder=""
                        [formControlName]="'commercialUseDesc'" [ngClass]="{
              'is-invalid':
                submitted && selectedQuestion3 && f.commercialUseDesc.errors
            }"></textarea>
                    <div class="card pd-20 col-12">
                        <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" class="custom-file pd-b-90">
                            <div>
                                <input [formControlName]="'uploadFile'" type="file" name="profile"
                                    (change)="onFileSelect($event)" />
                            </div>
                            <div class="mg-t-10">
                                <button type="submit" class="btn-medium btn-primary btn-oblong">
                                    Upload
                                </button>
                            </div>
                        </form>
                        <label class="col-12 control-label"> Uploaded files </label>

                        <ul class="list-group col-12 pd-5 justify-content-around">
                            <li *ngFor="let attachment of attachments; let i = index" class="row">
                                <div class="col-8">{{ attachment.filename }}</div>
                                <button class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold col-2"
                                    (click)="deleteAttachment(attachment)" type="button">
                                    <i class=" ion-trash-a mg-r-5"></i>
                                </button>
                                <button class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold col-2"
                                    (click)="downloadAttachment(attachment)" type="button">
                                    <i class="fa fa-download mg-r-5"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex pd-l-50 pd-r-15 justify-content-end mg-b-5">
            <div class="col-10"></div>
            <button type="submit" class="btn btn-oblong btn-primary btn btn-block col-2">
                Continue
            </button>
        </div>
    </form>
</div>