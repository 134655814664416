import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ChartistModule } from 'ng-chartist';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CreateNewClaimComponent } from './new-claim/create-new-claim/create-new-claim.component';
import { AppRoutingModule } from './app-routing.module';
import { ClaimInformationComponent } from './new-claim/claim-information/claim-information.component';
import { ClaimComplaintsComponent } from './new-claim/claim-complaints/claim-complaints.component';
import { SearchContractComponent } from './new-claim/search-contract/search-contract.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { ClaimDetailsComponent } from './new-claim/claim-details/claim-details.component';
import { ContractInformationComponent } from './new-claim/contract-information/contract-information.component';
import { JobSummaryComponent } from './new-claim/job-summary/job-summary.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ClaimListComponent } from './claim-list/claim-list.component';
import { ClaimListDetailTabsComponent } from './claim-list-detail-tabs/claim-list-detail-tabs.component';
import { DraftClaimListComponent } from './draft-claim-list/draft-claim-list.component';
import { ClaimSubmitComponent } from './new-claim/claim-submit/claim-submit.component';
import { SearchContractResultsComponent } from './search-contract-results/search-contract-results.component';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';


import {
  OKTA_CONFIG,
  OktaAuthGuard,
  OktaAuthModule,
  OktaCallbackComponent,
} from '@okta/okta-angular';
import { Routes } from '@angular/router';
import config from './app.config';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor } from './_helpers/basic-auth.interceptor';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NewItemComponent } from './release-notes/new-item/new-item.component';
import { NewItemListComponent } from './release-notes/new-item-list/new-item-list.component';
import { ReleaseNotesMainComponent } from './release-notes/release-notes-main/release-notes-main.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CreateNewClaimComponent,
    ClaimInformationComponent,
    ClaimComplaintsComponent,
    SearchContractComponent,
    HeaderComponent,
    FooterComponent,
    AppLayoutComponent,
    ClaimDetailsComponent,
    ContractInformationComponent,
    SearchContractResultsComponent,
    JobSummaryComponent,
    ClaimListComponent,
    ClaimListDetailTabsComponent,
    DraftClaimListComponent,
    ClaimSubmitComponent,
    ConfirmActionComponent,
    NewItemComponent,
    NewItemListComponent,
    ReleaseNotesMainComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ChartistModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    OktaAuthModule,
    ToastrModule.forRoot(),
    NgbModule,
    NgbPaginationModule,
    NgxDropzoneModule,
    NgbTypeaheadModule
  ],
  providers: [
    { provide: OKTA_CONFIG, useValue: config.oidc },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    DatePipe,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
