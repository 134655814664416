import { Component, OnDestroy } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy {
  title = 'dcit-front';
  isAuthenticated: boolean;
  baseUri: string;
  header: string = "Session Expiration";
  message: string = "Your session is about to expire, do you wish to extend it?";
  buttonMessage: string = "Yes"
  indexNum: number = 123456789;
  showExtendSession: boolean = false;
  sessionAnswered: boolean = false;
  statusUpdateSubscription: Subscription;

  constructor(public oktaAuth: OktaAuthService) {
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated) => {
        (this.isAuthenticated = isAuthenticated);
      }
    );
  }
  ngOnDestroy(): void {
    this.statusUpdateSubscription?.unsubscribe();
  }

  async ngOnInit() {
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    let duration = this.oktaAuth?.authStateManager?._authState?.idToken?.claims?.exp - this.oktaAuth?.authStateManager?._authState?.idToken?.claims?.iat;
    if (!duration || duration < 3000) {
      duration = 3000;
    }
    const source = interval((duration - 300) * 1000);
    this.statusUpdateSubscription = source.subscribe(() => {
      document.getElementById("sessionExpiration").click();
    });
  }

  login() {
    this.oktaAuth.signInWithRedirect();
  }

  logout() {
    this.oktaAuth.signOut();
  }

  refreshSession($event) {
    if ($event) {
      this.oktaAuth.session.refresh().then((e: any) => {
        console.log(e);
      }).catch((error) => {
        console.log("Promise rejected with " + JSON.stringify(error));
      });
      document.getElementById("closeConfirmActionButton123456789")?.click();
    }
  }
}
