import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Contract } from '../_model/contract';
import { ClaimService } from '../_services/claim.service';
import { ContractService } from '../_services/contract.service';

@Component({
  selector: 'app-search-contract-results',
  templateUrl: './search-contract-results.component.html',
  styleUrls: ['./search-contract-results.component.css'],
})
export class SearchContractResultsComponent implements OnInit, OnDestroy {
  contractsSubscription: Subscription;
  contracts: Contract[] = [];
  showDetails1: boolean = false;
  showDetails2: boolean = false;
  showDetails3: boolean = false;
  indexNum = 0;
  customerInformation1: string = 'customerInformation1';
  vehicleInformation1: string = 'vehicleInformation1';
  contractInformation1: string = 'contractInformation1';
  customerInformation2: string = 'customerInformation2';
  vehicleInformation2: string = 'vehicleInformation2';
  contractInformation2: string = 'contractInformation2';
  contractListShow: boolean[] = [];
  cancelledContract = "Contract is cancelled. No claims may be submitted.";
  expiredContract = "Contract is expired. No claims may be submitted.";
  pendingContract = "Contact F&I Customer Service for additional information 888-835-5063";

  constructor(
    private contractService: ContractService,
    private router: Router,
    private toastr: ToastrService,
    private claimService: ClaimService
  ) { }

  ngOnInit(): void {
    this.contractsSubscription = this.contractService.contracts.subscribe(
      (value) => {
        this.contracts = value;
        if (this.contracts)
          for (let i = 0; i < this.contracts.length; i++)
            this.contractListShow[i] = false;
      }
    );
  }

  previewDetails1(contract: Contract, i: number) {
    this.contractService.contract.next(this.contracts[i]);
    this.contractListShow[i] = !this.contractListShow[i];
    if (this.contractListShow[i]) {
      this.contractService.contractIndex.next(i);
      this.contractService.contractId.next(contract.contractNumber);
      this.claimService.currentStep = 0;
      this.setContract(contract, false);
    }
  }

  setContract(contract, goToNewClaim) {
    this.contractService.contract.next(contract);
    if (goToNewClaim) {
      if (contract.contractStatus.trim().toLocaleLowerCase() != "Active".toLocaleLowerCase()) {
        this.toastr.info('Contract is not Active', 'Start New Claim', {
          positionClass: 'toast-top-center',
        });
        return;
      }
      this.claimService.editClaim = false;
      this.claimService.job.next(null);
      this.claimService.claim.next(null);
      this.claimService.previewActiveClaim = false;
      this.claimService.currentStep = 0;
      this.claimService.startClaimFromSearch = true;
      this.claimService.currentUrl.next("NONE");
      this.router.navigate(['/new-claim']);
    }
  }

  ngOnDestroy() {
    this.contractsSubscription?.unsubscribe();
  }

}
