<div class="slim-mainpanel">
    <div class="container-fluid pd-lg-x-120">
        <div class="card pd-20 mg-t-60">
            <div class="row mg-l-0 mg-r-0 justify-content-between align-items-center pd-r-5">
                <h2 class="pd-l-0 col-md-4 tx-primary">
                    <i class="ion-document-text pd-r-10"></i>
                    Active Claims ({{ totalSize }})
                </h2>
                <div class="row">
                    <div class="col-xs-4">
                        <div class="d-flex align-items-center justify-content-between pd-y-5">
                            <div class="col-xs-12">
                                <button class="btn btn-oblong btn-zurich-blue pd-y-5 mg-r-10 " data-toggle="modal"
                                    data-target="#filterModal">
                                    Filter
                                    <span class="icon icon--filter-horizontal_48_outline mg-r-1 zurich-blue"></span>
                                </button>
                            </div>
                            <div class="col-xs-12">
                                <button (click)="filterClaimsAll(false)" class="btn btn-oblong btn pd-y-5 mg-r-10"
                                    [ngClass]="{
            'btn-primary': allPressed,
            'btn-secondary': !allPressed
          }">
                                    All
                                </button>
                            </div>

                        </div>
                    </div>
                    <ng-template #statusAuthorized>

                        <span>
                            An authorized claim shows the authorized amount and claim number. Click the details button
                            to find notes from your Claims Professional.<br>

                        </span>
                        <span>
                            Adjustments to jobs and individual items can be identified by clicking/hovering on the <span
                                class="icon icon--gear_24_outline mg-r-1"></span> icon.
                        </span>
                    </ng-template>
                    <ng-template #statusCancelled>

                        <span>
                            The claim has been cancelled due to one of the following reasons: Authorized but incomplete
                            paperwork received, <br>
                        </span>
                        <span>Authorized but no invoice received, Authorized but no sublet received, or Authorized but
                            not-billed on invoice.</span>
                    </ng-template>
                    <div class="col-xs-4">
                        <div class="d-flex align-items-center justify-content-between pd-y-5">

                            <div class="col-xs-12">
                                <button (click)="filterClaimsAuthorized(false)"
                                    class="btn btn-oblong btn pd-y-5 mg-r-10" [ngClass]="{
            'btn-primary': authorizedPressed,
            'btn-secondary': !authorizedPressed
          }">
                                    Authorized
                                </button>
                            </div>
                            <div class="col-xs-12 ">
                                <button (click)="filterClaimsRequested(false)" class="btn btn-oblong btn pd-y-5 mg-r-10"
                                    [ngClass]="{
            'btn-primary': requestedPressed,
            'btn-secondary': !requestedPressed
          }">
                                    Requested
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="pageLoading" class="spinner-border row align-self-center" style="width: 3rem; height: 3rem;"
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="table-wrapper table-responsive">
                <table *ngIf="!pageLoading" id="datatable1" class="table table-expandable display responsive nowrap">
                    <thead>
                        <tr>
                            <th class="text-center bg-white"></th>
                            <th class="text-center bg-white zurich-blue valign-middle">Status
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'statusCodeASC' && sortFieldAndDirection != 'statusCodeDESC') "
                                    style="cursor: pointer;" (click)="sort('statusCode')"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'statusCodeASC'" (click)="sort('statusCode')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'statusCodeDESC'" (click)="sort('statusCode')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Created date
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'sysDateIASC' && sortFieldAndDirection != 'sysDateIDESC') "
                                    (click)="sort('sysDateI')" style="cursor: pointer;"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'sysDateIASC'" (click)="sort('sysDateI')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'sysDateIDESC'" (click)="sort('sysDateI')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Repair Order No.
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'repairOrderNumberASC' && sortFieldAndDirection != 'repairOrderNumberDESC') "
                                    (click)="sort('repairOrderNumber')" style="cursor: pointer;"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'repairOrderNumberASC'"
                                    (click)="sort('repairOrderNumber')" style="cursor: pointer;"
                                    class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'repairOrderNumberDESC'"
                                    (click)="sort('repairOrderNumber')" style="cursor: pointer;"
                                    class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Vehicle information
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'vehicleInfoASC' && sortFieldAndDirection != 'vehicleInfoDESC') "
                                    (click)="sort('vehicleInfo')" style="cursor: pointer;"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'vehicleInfoASC'" (click)="sort('vehicleInfo')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'vehicleInfoDESC'" (click)="sort('vehicleInfo')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Customer name
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'customerNameASC' && sortFieldAndDirection != 'customerNameDESC') "
                                    (click)="sort('customerName')" style="cursor: pointer;"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'customerNameASC'" (click)="sort('customerName')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'customerNameDESC'" (click)="sort('customerName')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Amount
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'amountASC' && sortFieldAndDirection != 'amountDESC') "
                                    (click)="sort('amount')" style="cursor: pointer;"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'amountASC'" (click)="sort('amount')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'amountDESC'" (click)="sort('amount')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Authorization number
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'claimNumberASC' && sortFieldAndDirection != 'claimNumberDESC') "
                                    (click)="sort('claimNumber')" style="cursor: pointer;"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'claimNumberASC'" (click)="sort('claimNumber')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'claimNumberDESC'" (click)="sort('claimNumber')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue" style="display: none;"></th>
                            <th class="bg-white"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let claim of claimList; let i = index">
                            <tr>
                                <td>
                                    <i (click)="previewDetails1(claim.contractNumber, i)" *ngIf="this.claimListShow[i]"
                                        class="ion-ios-arrow-up" data-toggle="collapse"
                                        [attr.data-target]="'#collapse' + i" style="cursor: pointer;"></i>
                                    <i (click)="previewDetails1(claim.contractNumber, i)" *ngIf="!this.claimListShow[i]"
                                        class="ion-ios-arrow-down" data-toggle="collapse"
                                        [attr.data-target]="'#collapse' + i" style="cursor: pointer;"></i>
                                </td>
                                <td class="text-center" *ngIf="claim.statusCode == '0'"><span
                                        class="badge badge-pill badge-success"
                                        style="line-height: 2;background-color: #888888; font-size: 85%; width: 148.7px;height: 28.94px;">{{
                                        claim.status }}</span></td>
                                <td class="text-center" *ngIf="claim.statusCode == '10'"><span placement="right"
                                        ngbTooltip="This claim has not been submitted yet.  Draft Claims have their own list as they are not considered active claims."
                                        class="badge badge-pill badge-success"
                                        style="line-height: 2; background-color:#6c757d; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                        claim.status }}</span></td>
                                <td class="text-center" *ngIf="claim.statusCode == '30'"><span placement="right"
                                        ngbTooltip="Additional documents/information has been requested to further processing of the claim."
                                        class="badge badge-pill badge-success"
                                        style="line-height: 2; background-color:#b19d2e; font-size: 85%; width: 148.7px !important; height: 28.94px !important;"><span
                                            class="icon icon--flag_48_outline mg-r-1 tx-white"></span>Claim under
                                        review</span></td>
                                <td class="text-center" *ngIf="claim.statusCode == '50'"><span placement="right"
                                        ngbTooltip="The decision to deny the claim has been made. Please review the notes section for additional details."
                                        class="badge badge-pill badge-success"
                                        style="line-height: 2; background-color:#CB4B40; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                        claim.status }}</span></td>
                                <td class="text-center" *ngIf="claim.statusCode == '40'"><span placement="right"
                                        [ngbTooltip]="statusAuthorized" class="badge badge-pill badge-success"
                                        style="line-height: 2; background-color:#428351; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                        claim.status }}</span></td>
                                <td class="text-center" *ngIf="claim.statusCode == '90'"><span placement="right"
                                        ngbTooltip="Claims in this status have been submitted for payment."
                                        class="badge badge-pill badge-success"
                                        style="line-height: 2; background-color:#428351; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                        claim.status }}</span></td>
                                <td class="text-center" *ngIf="claim.statusCode == '20'"><span placement="right"
                                        ngbTooltip="The claim has been submitted successfully, the claims department has been notified, and are actively reviewing the claim."
                                        class="badge badge-pill badge-success"
                                        style="line-height: 2; background-color:#428351; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                        claim.status }}</span></td>
                                <td class="text-center" *ngIf="claim.statusCode == '60'"><span placement="right"
                                        ngbTooltip="Payment has been issued for claims in this status."
                                        class="badge badge-pill badge-success"
                                        style="line-height: 2; background-color:#428351; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                        claim.status }}</span></td>
                                <td class="text-center" *ngIf="claim.statusCode == '80'"><span placement="right"
                                        [ngbTooltip]="statusCancelled" class="badge badge-pill badge-success"
                                        style="line-height: 2; background-color:#CB4B40; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                        claim.status }}</span></td>

                                <td class="text-center">{{ claim.sysDateI | date }}</td>
                                <td class="text-center">{{ claim.repairOrderNumber }}</td>
                                <td class="text-center">{{ claim.year }} {{ claim.make }} {{ claim.model }}</td>
                                <td class="text-center">{{ claim.firstName }} {{ claim.lastName }}</td>
                                <td class="text-center">{{ claim.totalAgreed | currency: "$" }}</td>
                                <td class="text-center" [ngClass]="{ 'tx-bold tx-20': !claim.claimNumber }">{{
                                    claim.claimNumber && (claim.statusCode == 40 || claim.statusCode == 60 ||
                                    claim.statusCode == 80 || claim.statusCode == 90) ? claim.claimNumber : "-" }}</td>
                                <td class="text-center">
                                    <div class="row justify-content-center mg-r-0">

                                        <button (click)="continueToStepper(claim)"
                                            class="btn table-button btn-oblong btn-primary btn btn-sm"
                                            style="cursor: pointer;">
                                            Details
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr class="hide-table-padding">
                                <td colspan="12" class="p-0 m-0">
                                    <div [attr.aria-expanded]="claimListShow[i]" id="collapse{{ i }}"
                                        class="card row mg-l-0 mg-r-0 collapse out">
                                        <app-claim-list-detail-tabs [customerInformation]="customerInformation1 + i"
                                            [vehicleInformation]="vehicleInformation1 + i"
                                            [contractInformation]="contractInformation1 + i" [indexNum]="indexNum + i"
                                            [hideButton]="hideButton" [activeClaim]="activeClaim">
                                        </app-claim-list-detail-tabs>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="row justify-content-end">
                <ngb-pagination [(collectionSize)]="totalSize" [(page)]="page" [maxSize]="4" [rotate]="true"
                    [(pageSize)]="pageSize" (pageChange)="onPageChange(page)" [boundaryLinks]="true"></ngb-pagination>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="filterModal">
    <div class="modal-dialog modal-lg">
        <form fxLayout="column" [formGroup]="filterForm" (ngSubmit)="submitFilterForm()">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Filter</h4>
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                </div>

                <div class="modal-body pd-x-20">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="form-control-label">Authorization Number </label>
                                <input class="form-control" type="text" [formControlName]="'claimNumber'"
                                    name="claimNumber" />
                            </div>

                            <div class="form-group">
                                <label class="form-control-label">RO #</label>
                                <input class="form-control" type="text" [formControlName]="'roNumber'"
                                    name="roNumber" />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">Vehicle Information</label>
                                <input class="form-control" type="text" [formControlName]="'vehicleInfo'"
                                    name="vehicleInfo" />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">Created Date From</label>
                                <div class="input-group">
                                    <input type="date" class="form-control fc-datepicker" placeholder="dd-mm-yyyy"
                                        [formControlName]="'createdDateFrom'" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">User Name</label>
                                <input class="form-control" type="text" [formControlName]="'userName'"
                                    name="userName" />
                            </div>
                        </div>

                        <div class="col-6">

                            <div class="form-group mg-b-10-force">
                                <label class="form-control-label">Status</label>
                                <select [formControlName]="'claimStatus'" class="form-control select2"
                                    data-placeholder="Select status">
                                    <option label="" [selected]="true" [hidden]="false"></option>
                                    <option *ngFor="let status of statuses" [ngValue]="status">
                                        {{ status.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label class="form-control-label">Customer Name</label>
                                <input class="form-control" type="text" [formControlName]="'customerName'"
                                    name="customerName" />
                            </div>

                            <div class="form-group">
                                <label class="form-control-label">Amount $</label>
                                <input class="form-control" [formControlName]="'amount'" type="text" name="amount"
                                    [ngClass]="{
                'is-invalid': filterFormSubmitted && filterForm?.controls?.amount?.errors
              }" />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">Created Date To</label>

                                <div class="input-group">
                                    <input type="date" class="form-control fc-datepicker" placeholder="dd-mm-yyyy"
                                        [formControlName]="'createdDateTo'" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-oblong btn-secondary btn" data-dismiss="modal">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-oblong btn-primary btn">
                        Apply Filter

                    </button>
                    <div *ngIf="filterLoading" class="spinner-border row align-self-center"
                        style="width: 2rem; height: 2rem;" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>