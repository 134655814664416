<div class="slim-mainpanel">
    <div class="container-fluid pd-xl-x-120">
        <div class="section-wrapper  mg-t-20 row">
            <div class="table-wrapper pd-b-20 table-responsive">
                <table id="datatable1" class="table table-expandable display responsive nowrap">
                    <ng-template #statusAuthorized>

                        <span>
                            An authorized claim shows the authorized amount and claim number. Click the details button
                            to find notes from your Claims Professional.<br>

                        </span>
                        <span>
                            Adjustments to jobs and individual items can be identified by clicking/hovering on the <span
                                class="icon icon--gear_24_outline mg-r-1"></span> icon.
                        </span>
                    </ng-template>
                    <ng-template #statusCancelled>

                        <span>
                            The claim has been cancelled due to one of the following reasons: Authorized but incomplete
                            paperwork received, <br>
                        </span>
                        <span>Authorized but no invoice received, Authorized but no sublet received, or Authorized but
                            not-billed on invoice.</span>
                    </ng-template>
                    <ng-template #statusSending>

                        <span>
                            The claim is in the process of being sent to the system. This process might take a few
                            seconds. <br>
                        </span>
                        <span>These claims appear in the Draft section until received by Zurich.<br></span>
                        <span>Please report claims using the support feature: “Report a bug” if the claim is in this
                            status for more than a few minutes.</span>
                    </ng-template>
                    <thead>
                        <tr>
                            <th class="text-center bg-white zurich-blue"></th>
                            <th class="text-center bg-white zurich-blue valign-middle">Status</th>
                            <th class="text-center bg-white zurich-blue valign-middle">Created date</th>
                            <th class="text-center bg-white zurich-blue valign-middle">Repair Order No.</th>
                            <th class="text-center bg-white zurich-blue valign-middle">Vehicle information</th>
                            <th class="text-center bg-white zurich-blue valign-middle">Customer name</th>
                            <th class="text-center bg-white zurich-blue valign-middle">Amount</th>
                            <th class="text-center bg-white zurich-blue valign-middle">Authorization number</th>
                            <th class="text-center bg-white zurich-blue valign-middle"></th>
                            <th class="text-center bg-white zurich-blue" style="display: none;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <i (click)="previewDetails(claim?.contractNumber)" *ngIf="this.claimShow"
                                    class="ion-ios-arrow-up" data-toggle="collapse"
                                    [attr.data-target]="'#collapse'+indexNum" style="cursor: pointer;"></i>
                                <i (click)="previewDetails(claim?.contractNumber)" *ngIf="!this.claimShow"
                                    class="ion-ios-arrow-down" data-toggle="collapse"
                                    [attr.data-target]="'#collapse'+indexNum" style="cursor: pointer;"></i>
                            </td>
                            <td class="text-center" *ngIf="!claim?.statusCode"></td>
                            <td class="text-center" *ngIf="claim?.statusCode == 0"><span
                                    class="badge badge-pill badge-success"
                                    style="line-height: 2;background-color: #888888; font-size: 85%; width: 148.7px;height: 28.94px;">{{
                                    claim.status }}</span></td>
                            <td class="text-center" *ngIf="claim?.statusCode == 10"><span placement="right"
                                    ngbTooltip="This claim has not been submitted yet. Draft Claims have their own list as they are not considered active claims."
                                    class="badge badge-pill badge-success"
                                    style="line-height: 2; background-color:#6c757d; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                    claim.status }}</span></td>
                            <td class="text-center" *ngIf="claim?.statusCode == 30"><span placement="right"
                                    ngbTooltip="Additional documents/information has been requested to further processing of the claim."
                                    class="badge badge-pill badge-success"
                                    style="line-height: 2; background-color:#b19d2e; font-size: 85%; width: 148.7px !important; height: 28.94px !important;"><span
                                        class="icon icon--flag_48_outline mg-r-1 tx-white"></span>Claim under
                                    review</span></td>
                            <td class="text-center" *ngIf="claim?.statusCode == 50"><span placement="right"
                                    ngbTooltip="The decision to deny the claim has been made. Please review the notes section for additional details."
                                    class="badge badge-pill badge-success"
                                    style="line-height: 2; background-color:#CB4B40; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                    claim.status }}</span></td>
                            <td class="text-center" *ngIf="claim?.statusCode == 40"><span placement="right"
                                    [ngbTooltip]="statusAuthorized" class="badge badge-pill badge-success"
                                    style="line-height: 2; background-color:#428351; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                    claim.status }}</span></td>
                            <td class="text-center" *ngIf="claim?.statusCode == 90"><span placement="right"
                                    ngbTooltip="Claims in this status have been submitted for payment."
                                    class="badge badge-pill badge-success"
                                    style="line-height: 2; background-color:#428351; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                    claim.status }}</span></td>
                            <td class="text-center" *ngIf="claim?.statusCode == 20"><span placement="right"
                                    ngbTooltip="The claim has been submitted successfully, the claims department has been notified, and are actively reviewing the claim."
                                    class="badge badge-pill badge-success"
                                    style="line-height: 2; background-color:#428351; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                    claim.status }}</span></td>
                            <td class="text-center" *ngIf="claim?.statusCode == 60"><span placement="right"
                                    ngbTooltip="Payment has been issued for claims in this status."
                                    class="badge badge-pill badge-success"
                                    style="line-height: 2; background-color:#428351; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                    claim.status }}</span></td>
                            <td class="text-center" *ngIf="claim?.statusCode == 70"><span placement="right"
                                    [ngbTooltip]="statusSending" class="badge badge-pill badge-success"
                                    style="line-height: 2; background-color:#6c757d; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                    claim.status }}</span></td>
                            <td class="text-center" *ngIf="claim?.statusCode == 80"><span placement="right"
                                    [ngbTooltip]="statusCancelled" class="badge badge-pill badge-success"
                                    style="line-height: 2; background-color:#CB4B40; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                    claim.status }}</span></td>
                            <td class="text-center">{{ claim?.sysDateI | date }}</td>
                            <td class="text-center">{{ claim?.repairOrderNumber }}</td>
                            <td class="text-center">{{ claim?.year }} {{ claim?.make }} {{ claim?.model }}</td>
                            <td class="text-center">{{ claim?.firstName }} {{ claim?.lastName }}</td>
                            <td class="text-center"
                                *ngIf="claim?.claimNumber && (claim?.statusCode == 40 || claim?.statusCode == 60 || claim?.statusCode == 80 || claim?.statusCode == 90)">
                                {{
                                claim?.amount
                                | currency: "$"
                                }}
                            </td>
                            <td class="text-center tx-bold tx-20"
                                *ngIf="!claim?.claimNumber || (claim?.statusCode != 40 && claim?.statusCode != 60 && claim?.statusCode != 80 && claim?.statusCode != 90)">
                                -</td>

                            <td class="text-center" [ngClass]="{ 'tx-bold tx-20': !claim?.claimNumber }">{{
                                claim?.claimNumber && (claim?.statusCode == 40 || claim?.statusCode == 60 ||
                                claim?.statusCode == 80 || claim?.statusCode == 90) ? claim?.claimNumber : "-" }}</td>
                        </tr>
                        <tr class="hide-table-padding">
                            <td colspan="12" class="p-0 m-0">
                                <div [attr.aria-expanded]="claimShow" id="collapse{{indexNum}}"
                                    class="card row mg-l-0 mg-r-0 collapse out">
                                    <app-claim-list-detail-tabs [customerInformation]="customerInformation"
                                        [vehicleInformation]="vehicleInformation"
                                        [contractInformation]="contractInformation" [indexNum]="indexNum"
                                        [hideButton]="hideButton" [activeClaim]="activeClaim">
                                    </app-claim-list-detail-tabs>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 pd-x-0">
                <div class="row ">
                    <div class="col-4"></div>
                    <div class="col-4 ">
                        <div class="row justify-content-center">
                            <h3 class="tx-black tx-medium mg-b-20">
                                {{ currentStepTitles[previousStep] }}
                            </h3>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row justify-content-end">
                            <a id="noteSectionOpener" class="nav-link pd-r-0 " *ngIf="previousStep != 2"
                                (click)="openNoteSection()" data-toggle="modal" data-target="#noteModal"
                                data-backdrop='static' data-keyboard='false'>
                                <i placement="top" ngbTooltip="Add Note"
                                    class="fa fa-2x far tx-primary fa-edit mg-r-10"></i>
                            </a>
                            <a class="nav-link pd-r-0 " *ngIf="previousStep == 2">
                                <i placement="top" (click)="scroll('notesSection')" ngbTooltip="Add Note"
                                    class="fa fa-2x far tx-primary fa-edit mg-r-10"></i>
                            </a>
                            <a class="nav-link pd-l-5 " (click)="removeFiles()" *ngIf="previousStep != 2"
                                data-toggle="modal" data-target="#attachmentModal">
                                <i placement="top" ngbTooltip="Add Attachment"
                                    class="fa fa-2x far tx-primary fa-paperclip mg-r-10"></i>
                            </a>
                            <a class="nav-link pd-l-5 " *ngIf="previousStep == 2">
                                <i placement="top" ngbTooltip="Add Attachment" (click)="scroll('attachmentsSection')"
                                    class="fa fa-2x far tx-primary fa-paperclip mg-r-10"></i> </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="slds-path col-12 pd-x-0">
                <div class="slds-grid slds-path__track">
                    <div class="slds-grid slds-path__scroller-container">
                        <div class="slds-path__scroller">
                            <div class="slds-path__scroller_inner">
                                <ul class="slds-path__nav" role="listbox" aria-orientation="horizontal">
                                    <li class="slds-path__item" role="presentation" [ngClass]="{
                      'slds-is-current slds-is-active': previousStep == 0,
                      'slds-is-complete': (currentStep > 0 && previousStep != 0)
                    }" (click)="goToPreviousStep(0)">
                                        <a aria-selected="true" class="slds-path__link" id="path-1" role="option"
                                            tabindex="0">
                                            <span class="slds-path__title">Claim Information</span>
                                        </a>
                                    </li>
                                    <li class="slds-path__item slds-is-incomplete" role="presentation" [ngClass]="{
                      'slds-is-complete':  (currentStep > 1 && previousStep != 1),
                      'slds-is-current slds-is-active': previousStep == 1
                    }" (click)="goToPreviousStep(1)">
                                        <a aria-selected="false" class="slds-path__link" id="path-2" role="option"
                                            tabindex="-1">
                                            <span class="slds-path__stage"> </span>
                                            <span class="slds-path__title">Job Summary</span>
                                        </a>
                                    </li>
                                    <li class="slds-path__item slds-is-incomplete" role="presentation" [ngClass]="{
                      'slds-is-complete':  (currentStep > 2 && previousStep != 2),
                      'slds-is-current slds-is-active': previousStep == 2
                    }" (click)="goToPreviousStep(2)">
                                        <a aria-selected="false" class="slds-path__link" id="path-3" role="option"
                                            tabindex="-1">
                                            <span class="slds-path__stage">
                                                <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                                                    <use
                                                        xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check">
                                                    </use>
                                                </svg>
                                            </span>
                                            <span class="slds-path__title">Claim Details</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-claim-information (messageEvent)="goToNextStep()" (repairOrderNumberEvent)="getRepairOrderNum($event)"
                *ngIf="(currentStep == 0 && !chevronPressed) || (previousStep == 0 && chevronPressed)"
                class="card col-12"></app-claim-information>


            <app-claim-details (messageEvent)="goToJobsSummary($event)"
                *ngIf="(currentStep == 1 && !chevronPressed && !goToJobsSummaryBoolean) || (previousStep == 1 && chevronPressed)"
                class="card col-12"></app-claim-details>


            <app-claim-submit *ngIf="(currentStep == 2 && !chevronPressed) || (previousStep == 2 && chevronPressed)"
                class="card bd-0 col-12 pd-x-0"></app-claim-submit>
        </div>
    </div>
</div>
<app-confirm-action [ngClass]="{ 'zIndexDraft' : openedConfirmDialog }" [header]="header" [message]="message"
    [buttonMessage]="buttonMessage" [indexNum]="indexNumNote" [hideCloseButton]="hideCloseButton"
    (confirmActionEvent)="returnToNotes($event)"></app-confirm-action>
<ng-template #notesInfo>
    <div class="row pd-x-20 tx-black pd-b-0 justify-content-center notes-info-tooltip"
        style="background-color: #f8f9fa; margin-top: -8px; border-top: 1px solid black;">
        <span class="tx-14 mg-b-0 tx-uppercase tx-bold" style="width: 200px; ">
            Notes
        </span>

    </div>
    <div class="row tx-black pd-t-10 bd-0 notes-info-tooltip justify-content-center"
        style="background-color: white; margin-bottom:-8px; border-top: 1px solid black;">
        Notes are the primary way of communicating with your Zurich Adjuster.

    </div>
    <div class="row tx-black pd-t-10 bd-0 notes-info-tooltip justify-content-center"
        style="background-color: white; margin-bottom:-8px;">
        Notes can be used to answer questions, provide additional details, or request changes/updates to claims.

    </div>
    <div class="row tx-black pd-t-10 bd-0 notes-info-tooltip justify-content-center"
        style="background-color: white; margin-bottom:-8px;">
        Notes entered before submission will be reviewed by an adjuster when the claim is submitted.

    </div>
    <div class="row tx-black pd-t-10 bd-0 notes-info-tooltip justify-content-center"
        style="background-color: white; margin-bottom:-8px; border-bottom: 1px solid black;">
        Notes entered after the claim has been submitted will alert an adjuster that there are additional items to
        review.

    </div>
</ng-template>
<div id="noteModal" [ngClass]="{ 'zIndexNotes' : openedConfirmDialog }" class="modal">
    <div #noteModalVar class="modal-dialog modal-lg modal-width" role="document">
        <form fxLayout="column" [formGroup]="noteForm" (ngSubmit)="submitNoteForm()">
            <div class="modal-content tx-size-sm">
                <div class="modal-header pd-x-20">
                    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                        Add Note <i class="fa mg-l-5 fa-info-circle zurich-blue"
                            style="font-size: 1.35em; cursor: pointer;" placement="auto" triggers="click"
                            [ngbTooltip]="notesInfo"></i>
                    </h6>
                    <button type="button" class="close" aria-label="Close" id="closeNoteModalButton1"
                        (click)="closeNotesAlert()">

                        <span aria-hidden="true">&times;</span>
                    </button>
                    <button type="button" class="close" aria-label="Close" id="closeNoteModalButton"
                        data-dismiss="modal" style="display:none" (click)="closeNotes()"></button>
                </div>
                <div class="row">
                    <div class="col-md">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6 align-self-center pd-r-0">
                                        <h4 class="tx-primary">Notes </h4>
                                    </div>
                                    <div class="col-9 col-sm-9 col-md-10 pd-l-0">

                                        <textarea (keyup.enter)="submitNoteForm()" class="form-control " rows="1"
                                            placeholder="Type here..." [formControlName]="'noteText'" [ngClass]="{
                          'is-invalid': submittedNote && fNote.noteText.errors
                        }"></textarea>


                                    </div>
                                    <div class="col-3 col-sm-3 col-md-2 align-self-center pd-l-0 pd-r-0">
                                        <i placement="top" ngbTooltip="Add Note" style="cursor: pointer;"
                                            (click)="submitNoteForm()"
                                            class="fa fa-2x far tx-primary fa-send-o mg-r-10"></i>
                                        <i placement="top" style="cursor: pointer;" (click)="resetNoteForm()"
                                            ngbTooltip="Clear"
                                            class="fa fa-2x far tx-primary fa-times-circle mg-r-10"></i>
                                        <div *ngIf="sendingNote" class="spinner-border align-self-center"
                                            style="width: 2rem; height: 2rem;" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #attachmentTypes>
    <div class="row pd-x-20 tx-black pd-b-0" style="background-color: #f8f9fa; margin-top: -8px; width: 270px;
  border-right: 1px solid black; 
  border-left: 1px solid black;
  border-top: 1px solid black;">
        <span class="tx-14 mg-b-0 tx-uppercase tx-bold" style="width: 270px;">
            Accepted document types are:
        </span>
    </div>

    <div class="row pd-t-10 bd-0" style="background-color: white; margin-bottom:-8px; width: 270px;
  border-right: 1px solid black; 
    border-left: 1px solid black;
    border-bottom: 1px solid black;">
        <p class="col-4 tx-black">.bmp <br>.csv <br>.doc <br>.docx <br>.gif<br> .html <br>.ico<br>.jpeg</p>
        <p class="col-4 tx-black">.txt<br>.jpg<br> .pdf<br> .png <br>.ppt <br>.pptx <br>.rar<br> .rtf </p>
        <p class="col-4 tx-black">.tif<br>.tiff <br>.xls <br>.xlsx <br>.xml<br> .zip</p>
    </div>
</ng-template>
<div *ngIf="previousStep != 2" id="attachmentModal" class="modal">
    <div class="modal-dialog modal-lg modal-width-attachments" role="document">

        <div class="modal-content tx-size-sm">
            <div class="modal-header pd-x-20">
                <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold pd-r-15">
                    Add Attachments
                </h6>
                <i class="fa fa-info-circle zurich-blue" placement="auto" triggers="click"
                    [ngbTooltip]="attachmentTypes"></i>
                <button type="button" class="close" aria-label="Close" id="closeAttachmentFormButton"
                    data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="card">
                        <div class="card-body">
                            <div class="row justify-content-center pd-b-20">
                                <ngx-dropzone (change)="onSelectFiles($event)">

                                    <ngx-dropzone-label>
                                        <i placement="top" ngbTooltip="Add Attachment"
                                            class="fa fa-2x far tx-primary fa-upload mg-r-10"></i>
                                        <br>
                                        Drag and drop Files Here to Upload <br>
                                        or Click to Select Files
                                    </ngx-dropzone-label>

                                    <ngx-dropzone-preview *ngFor="let f of files; let i = index" [removable]="true"
                                        (removed)="onRemove(i)">

                                        <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>

                                    </ngx-dropzone-preview>

                                </ngx-dropzone>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>