<div class="slim-mainpanel">
    <div class="container-fluid pd-lg-x-120">
        <div class="stage" style="background-color: #5b93d3 !important">
            <div class="container mg-l-0 pd-l-40">
                <div class="stage-image">
                    <div class="stage-wrapper-custom">
                        <div class="safespace lg">
                            <svg>
                                <defs>
                                    <clipPath id="safespace-lg" clipPathUnits="objectBoundingBox">
                                        <path
                                            d="M399.356,0.288 C178.798,0.288 0,179.086 0,399.645 C0,495.084 33.485,582.7 89.34,651.398 L196.311,510.826 L184.779,510.845 L173.344,510.826 C161.123,510.826 149.662,511.693 141.379,513.302 C136.358,514.289 131.894,516.088 128.253,518.664 C123.979,521.66 118.628,528.018 115.126,534.267 C114.728,534.995 114.132,536.06 113.385,537.61 L107.129,536.186 L117.634,495.761 L251.818,495.761 L145.191,638.322 C152.119,637.708 154.898,637.55 160.521,637.55 C164.846,637.55 171.413,637.828 180.189,638.342 L199.134,639.512 C205.948,639.899 211.723,640.103 216.111,640.103 C225.875,640.103 232.227,639.342 238.539,637.41 C243.428,635.91 248.063,633.655 252.331,630.769 C259.815,625.575 267.097,617.294 271.454,608.967 C271.872,608.131 272.568,606.84 273.354,605.136 L279.059,606.585 C277.203,613.652 276.228,616.356 273.398,622.67 C267.667,635.467 261.195,644.04 252.312,650.606 C243.167,657.375 233.062,660.694 221.101,660.891 L219.277,660.909 C210.425,660.909 203.58,660.168 182.367,656.933 C160.8,653.607 149.814,652.475 138.979,652.475 C121.165,652.475 106.729,655.809 96.141,659.541 C169.383,744.911 278.051,799 399.356,799 C619.915,799 798.712,620.202 798.712,399.645 C798.712,179.086 619.915,0.288 399.356,0.288"
                                            transform="scale(0.00125, 0.00125)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div class="safespace__circle" style="
                  clip-path: url(#safespace-lg);
                  -webkit-clip-path: url(#safespace-lg);
                ">
                                <img src="./assets/slim/img/homepage_header.png" alt="stage image"
                                    style="transform: translateX(0%)" />
                            </div>
                        </div>

                        <div class="shape-decoration shape-decoration--stage-one">
                            <div class="shape-decoration__elements">
                                <span class="shape-decoration__half-mask">
                                    <span class="shape-decoration__half-mask__circle"></span>
                                </span>
                                <span class="shape-decoration__half-mask-small">
                                    <span class="shape-decoration__half-mask-small__circle"></span>
                                </span>
                                <span class="shape-decoration__midi-circle"></span>
                                <span class="shape-decoration__maxi-circle"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="stage-content">
                    <div>
                        <div class="stage-text">
                            <h1 class="pd-b-0 mg-b-0" style="margin-bottom: 0px !important">
                                Zurich Online Claims
                            </h1>
                            <p class="pd-t-0 mg-t-0 pd-b-32" style="padding-bottom: 25px !important">
                                Report claims, follow up and get your customers back on the
                                road
                            </p>
                            <p class="pd-t-0 mg-t-0">
                                To start a claim, enter the 17 digit VIN and customer's last
                                name
                            </p>

                        </div>
                        <div class="stage-link" style="padding-top: 0px !important">
                            <div class="media-body pd-0 col-12">
                                <div class="row">
                                    <form class="form-row col-12 align-items-baseline" [formGroup]="searchContractForm"
                                        (ngSubmit)="onClickSearch()">
                                        <div class="col-md-12 mg-r-15">
                                            <div class="row flex-nowrap pd-l-0 col-md-12 align-items-center">
                                                <div class="col-6 col-lg-5 mg-r-0" style="min-width: 220px !important;">
                                                    <div class="column">
                                                        <input [formControlName]="'vinNumber'" id="vinNumber"
                                                            type="text" style="min-width: 220px !important;"
                                                            class="form-control col-md-12 mg-r-15 rounded-40"
                                                            placeholder="Enter the 17 character VIN" [ngClass]="{
                                'is-invalid': submitted && f?.vinNumber?.errors
                              }" />

                                                    </div>
                                                </div>

                                                <div class="col-6 col-lg-5 mg-r-15"
                                                    style="min-width: 220px !important;">
                                                    <div class="column">
                                                        <input [formControlName]="'lastName'" type="text"
                                                            style="min-width: 220px !important;"
                                                            class="form-control col-md-12 mg-r-15 rounded-40"
                                                            placeholder="Enter the Customer's Last Name" [ngClass]="{
                                'is-invalid': submitted && f.lastName.errors
                              }" />

                                                    </div>
                                                </div>

                                                <button type="submit" class="btn btn-circle btn-primary btn-md"
                                                    [disabled]="disableSearchButton">
                                                    <span class="icon icon--arrow-right_48"></span>
                                                </button>
                                                <div *ngIf="disableSearchButton"
                                                    class="mg-l-5 spinner-border row align-self-center"
                                                    style="width: 3rem; height: 3rem;" role="status">
                                                    <span class="sr-only">Searching...</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-wrapper mg-t-20">

            <h4 class="tx-dark mg-t-30">
                <span class="icon icon--flag_48_outline mg-r-10 tx-danger"></span>
                <label>Action needed ({{ totalActionClaims }})</label>
            </h4>
            <div *ngIf="actionLoading" class="spinner-border row align-self-center" style="width: 3rem; height: 3rem"
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div *ngIf="!actionLoading" class="row pd-b-30">
                <div class="col-4">
                    <div class="card h-100 " *ngIf="actionList[0]">
                        <div class="card-header zurich-blue bg-info align-items-center pd-y-5 bd-b">
                            <div class="row mg-b-5 pd-x-15 align-items-center justify-content-between">
                                <div class="row mg-b-10 mg-r-5 mg-t-10 align-middle mg-l-2">
                                    <span class="icon icon--flag_48_outline mg-r-1 tx-white"></span>
                                    <h5 class="card-title text-wrapping tx-medium tx-white mg-r-5 mg-b-10 font-weight-bold"
                                        triggers="click" placement="top"
                                        ngbTooltip="{{actionList[0]?.repairOrderNumber}}">
                                        {{ actionList[0]?.repairOrderNumber }}
                                    </h5>
                                </div>

                                <button type="button"
                                    class="btn table-button btn-oblong zurich-blue bg-white btn btn-sm"
                                    (click)="continueToStepper(actionList[0])">
                                    Details<i class="mg-l-5 fa fa-angle-right"></i>
                                </button>

                            </div>
                            <p class="card-subtitle tx-white text-wrapping" triggers="click" placement="top"
                                ngbTooltip="{{ actionList[0]?.year }} {{ actionList[0]?.make }} {{ actionList[0]?.model }}">
                                {{ actionList[0]?.year }} {{ actionList[0]?.make }}
                                {{ actionList[0]?.model }}
                            </p>
                            <p class="card-subtitle tx-white text-wrapping" triggers="click" placement="top"
                                ngbTooltip=" {{ actionList[0]?.firstName }} {{ actionList[0]?.lastName }}">
                                {{ actionList[0]?.firstName }} {{ actionList[0]?.lastName }}
                            </p>
                        </div>
                        <div class="card-body">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <p class="tx-primary mg-b-15 tx-medium font-weight-bold tx-20" *ngIf="
                      actionList[0].statusCode == 30
                    ">
                                        Claim under review
                                    </p>
                                    <p class="tx-primary mg-b-15 tx-medium font-weight-bold tx-20" *ngIf="
                      actionList[0].statusCode != 30
                    ">
                                        {{ actionList[0]?.status }}
                                    </p>
                                </div>
                                <div class="row justify-content-center">
                                    <p class="p-style text-center">
                                        {{ getNoteForActionClaim(actionList[0]) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card h-100" *ngIf="actionList[1]">
                        <div class="card-header zurich-blue bg-info align-items-center pd-y-5 bd-b">
                            <div class="row mg-b-5 pd-x-15 align-items-center justify-content-between">
                                <div class="row mg-b-10 mg-r-5 mg-t-10 align-middle mg-l-2">
                                    <span class="icon icon--flag_48_outline mg-r-1 tx-white"></span>
                                    <h5 class="card-title text-wrapping tx-medium tx-white mg-r-5 mg-b-10 font-weight-bold"
                                        triggers="click" placement="top"
                                        ngbTooltip="{{actionList[0]?.repairOrderNumber}}">
                                        {{ actionList[1]?.repairOrderNumber }}
                                    </h5>
                                </div>

                                <button type="button" *ngIf="actionList[1]"
                                    class="btn table-button btn-oblong bg-white zurich-blue btn btn-sm"
                                    (click)="continueToStepper(actionList[1])">
                                    Details <i class="mg-l-5 fa fa-angle-right"></i>
                                </button>
                            </div>
                            <p class="card-subtitle tx-white text-wrapping" *ngIf="actionList[1]" triggers="click"
                                placement="top" ngbTooltip="{{ actionList[1]?.year }} {{ actionList[1]?.make }}
              {{ actionList[1]?.model }}">
                                {{ actionList[1]?.year }} {{ actionList[1]?.make }}
                                {{ actionList[1]?.model }}
                            </p>
                            <p class="card-subtitle tx-white text-wrapping" triggers="click" placement="top"
                                ngbTooltip=" {{ actionList[1]?.firstName }} {{ actionList[1]?.lastName }}">
                                {{ actionList[1]?.firstName }} {{ actionList[1]?.lastName }}
                            </p>
                        </div>
                        <div class="card-body">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <p class="tx-primary mg-b-15 tx-medium font-weight-bold tx-20" *ngIf="
                      actionList[1].statusCode == 30
                    ">
                                        Claim under review
                                    </p>
                                    <p class="tx-primary mg-b-15 tx-medium font-weight-bold tx-20" *ngIf="
                      actionList[1].statusCode != 30
                    ">
                                        {{ actionList[1]?.status }}
                                    </p>
                                </div>
                                <div class="row justify-content-center">
                                    <p class="p-style text-center">

                                        {{ getNoteForActionClaim(actionList[1]) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card h-100" *ngIf="actionList[2]">
                        <div class="card-header zurich-blue bg-info align-items-center pd-y-5 bd-b">
                            <div class="row mg-b-5 pd-x-15 align-items-center justify-content-between">
                                <div class="row mg-b-10 mg-r-5 mg-t-10 align-middle mg-l-2">
                                    <span class="icon icon--flag_48_outline mg-r-1 tx-white"></span>
                                    <h5 class="card-title text-wrapping tx-medium tx-white mg-r-5 mg-b-10 font-weight-bold"
                                        triggers="click" placement="top"
                                        ngbTooltip="{{ actionList[2]?.repairOrderNumber }}">
                                        {{ actionList[2]?.repairOrderNumber }}
                                    </h5>
                                </div>

                                <button type="button"
                                    class="btn table-button btn-oblong bg-white zurich-blue btn btn-sm"
                                    (click)="continueToStepper(actionList[2])">
                                    Details <i class="mg-l-5 fa fa-angle-right"></i>
                                </button>
                            </div>
                            <p class="card-subtitle tx-white text-wrapping" triggers="click" placement="top"
                                ngbTooltip=" {{ actionList[2]?.year }} {{ actionList[2]?.make }} {{ actionList[2]?.model }}">
                                {{ actionList[2]?.year }} {{ actionList[2]?.make }}
                                {{ actionList[2]?.model }}
                            </p>
                            <p class="card-subtitle tx-white text-wrapping" triggers="click" placement="top"
                                ngbTooltip="{{ actionList[2]?.firstName }} {{ actionList[2]?.lastName }}">
                                {{ actionList[2]?.firstName }} {{ actionList[2]?.lastName }}
                            </p>
                        </div>
                        <div class="card-body">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <p class="tx-primary mg-b-15 tx-medium font-weight-bold tx-20" *ngIf="
                      actionList[2].statusCode == 30
                    ">
                                        Claim under review
                                    </p>
                                    <p class="tx-primary mg-b-15 tx-medium font-weight-bold tx-20" *ngIf="
                      actionList[2].statusCode != 30
                    ">
                                        {{ actionList[2]?.status }}
                                    </p>
                                </div>
                                <div class="row justify-content-center">
                                    <p class="p-style text-center">

                                        {{ getNoteForActionClaim(actionList[2]) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- All claims list -->

            <div class="slim-pageheader pd-0 pd-t-20 align-items-baseline">
                <a (click)="setAll()" href="/claims" routerLink="/claims" class="card-link">View all active</a>
                <h4 class="tx-dark">
                    <i class="fa fa-clock-o mg-r-10"></i><label>Active Claims ({{ totalActiveClaims }})</label>
                </h4>
            </div>

            <div *ngIf="activeLoading" class="spinner-border row align-self-center" style="width: 3rem; height: 3rem"
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <ng-template #requestPayment>
                <div class="row pd-x-20 tx-black pd-b-10"
                    style="background-color: #f8f9fa; margin-top: -8px; width: 200px;">
                    <span class="tx-14 mg-b-0 tx-uppercase tx-bold" style="width: 200px;">
                        Request Payment
                    </span>

                </div>
                <div class="row tx-black pd-t-10 bd-0"
                    style="background-color: white; margin-bottom:-8px; width: 200px;">
                    Click authorized claims to submit your completed RO for payment

                </div>
            </ng-template>
            <ng-template #statusAuthorized>

                <span>
                    An authorized claim shows the authorized amount and claim number. Click the details button to find
                    notes from your Claims Professional.<br>

                </span>
                <span>
                    Adjustments to jobs and individual items can be identified by clicking/hovering on the <span
                        class="icon icon--gear_24_outline mg-r-1"></span> icon.
                </span>
            </ng-template>
            <ng-template #statusCancelled>

                <span>
                    The claim has been cancelled due to one of the following reasons: Authorized but incomplete
                    paperwork received, <br>
                </span>
                <span>Authorized but no invoice received, Authorized but no sublet received, or Authorized but
                    not-billed on invoice.</span>
            </ng-template>
            <div class="table-wrapper table-responsive">
                <table *ngIf="!activeLoading" id="datatable1" class="table display responsive nowrap">
                    <thead>
                        <tr>
                            <th class="text-center bg-white zurich-blue valign-middle">Status
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'statusCodeASC' && sortFieldAndDirection != 'statusCodeDESC') "
                                    style="cursor: pointer;" (click)="sort('statusCode')"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'statusCodeASC'" (click)="sort('statusCode')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'statusCodeDESC'" (click)="sort('statusCode')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Created date
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'sysDateIASC' && sortFieldAndDirection != 'sysDateIDESC') "
                                    style="cursor: pointer;" (click)="sort('sysDateI')"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'sysDateIASC'" (click)="sort('sysDateI')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'sysDateIDESC'" (click)="sort('sysDateI')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Repair Order No.
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'repairOrderNumberASC' && sortFieldAndDirection != 'repairOrderNumberDESC') "
                                    style="cursor: pointer;" (click)="sort('repairOrderNumber')"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'repairOrderNumberASC'"
                                    (click)="sort('repairOrderNumber')" style="cursor: pointer;"
                                    class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'repairOrderNumberDESC'"
                                    (click)="sort('repairOrderNumber')" style="cursor: pointer;"
                                    class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Vehicle information
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'vehicleInfoASC' && sortFieldAndDirection != 'vehicleInfoDESC') "
                                    style="cursor: pointer;" (click)="sort('vehicleInfo')"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'vehicleInfoASC'" (click)="sort('vehicleInfo')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'vehicleInfoDESC'" (click)="sort('vehicleInfo')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Customer name
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'customerNameASC' && sortFieldAndDirection != 'customerNameDESC') "
                                    style="cursor: pointer;" (click)="sort('customerName')"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'customerNameASC'" (click)="sort('customerName')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'customerNameDESC'" (click)="sort('customerName')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Amount
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'amountASC' && sortFieldAndDirection != 'amountDESC') "
                                    style="cursor: pointer;" (click)="sort('amount')"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'amountASC'" (click)="sort('amount')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'amountDESC'" (click)="sort('amount')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                                <i class="fa mg-l-5 fa-info-circle zurich-blue"
                                    style="font-size: 1.35em; cursor: pointer;" placement="auto" triggers="click"
                                    [ngbTooltip]="requestPayment"></i>

                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle">Authorization number
                                <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'claimNumberASC' && sortFieldAndDirection != 'claimNumberDESC') "
                                    style="cursor: pointer;" (click)="sort('claimNumber')"
                                    class="fa fa-sort bg-grey mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'claimNumberASC'" (click)="sort('claimNumber')"
                                    style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
                                <i *ngIf="sortFieldAndDirection == 'claimNumberDESC'" (click)="sort('claimNumber')"
                                    style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
                            </th>
                            <th class="text-center bg-white zurich-blue valign-middle" style="min-width: 130px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let claim of claimList | slice: 0:10; let i = index">
                            <tr>
                                <td *ngIf="claim.statusCode == '30'" class="text-center">
                                    <span placement="right"
                                        ngbTooltip="Additional documents/information has been requested to further processing of the claim."
                                        class="badge badge-pill badge-success" style="
                    line-height: 2;
                    background-color: #b19d2e;
                    font-size: 85%;
                    width: 148.7px !important;
                    height: 28.94px !important;
                  "><span class="icon icon--flag_48_outline mg-r-1 tx-white"></span>Claim under review</span>
                                </td>
                                <td *ngIf="claim.statusCode == '50'" class="text-center">
                                    <span placement="right"
                                        ngbTooltip="The decision to deny the claim has been made. Please review the notes section for additional details."
                                        class="badge badge-pill badge-success" style="
                    line-height: 2;
                    background-color: #CB4B40;
                    font-size: 85%;
                    width: 148.7px;
                    height: 28.94px;
                  ">{{ claim.status }}</span>
                                </td>
                                <td *ngIf="claim.statusCode == '40'" class="text-center">
                                    <span placement="right" [ngbTooltip]="statusAuthorized"
                                        class="badge badge-pill badge-success" style="
                    line-height: 2;
                    background-color: #428351;
                    font-size: 85%;
                    width: 148.7px;
                    height: 28.94px;
                  ">{{ claim.status }}</span>
                                </td>
                                <td *ngIf="claim.statusCode == '90'" class="text-center">
                                    <span placement="right"
                                        ngbTooltip="Claims in this status have been submitted for payment."
                                        class="badge badge-pill badge-success" style="
                    line-height: 2;
                    background-color: #428351;
                    font-size: 85%;
                    width: 148.7px;
                    height: 28.94px;
                  ">{{ claim.status }}</span>
                                </td>
                                <td *ngIf="claim.statusCode == '0'" class="text-center">
                                    <span class="badge badge-pill badge-success" style="
                    line-height: 2;
                    background-color: #e20a0ac2;
                    font-size: 85%;
                    width: 148.7px;
                    height: 28.94px;
                  ">{{ claim.status }}</span>
                                </td>
                                <td *ngIf="claim.statusCode == '20'" class="text-center">
                                    <span placement="right"
                                        ngbTooltip="The claim has been submitted successfully, the claims department has been notified, and are actively reviewing the claim."
                                        class="badge badge-pill badge-success" style="
                    line-height: 2;
                    background-color: #428351;
                    font-size: 85%;
                    width: 148.7px;
                    height: 28.94px;
                  ">{{ claim.status }}</span>
                                </td>
                                <td *ngIf="claim.statusCode == '60'" class="text-center">
                                    <span placement="right"
                                        ngbTooltip="Payment has been issued for claims in this status."
                                        class="badge badge-pill badge-success" style="
                    line-height: 2;
                    background-color: #428351;
                    font-size: 85%;
                    width: 148.7px;
                    height: 28.94px;
                  ">{{ claim.status }}</span>
                                </td>
                                <td *ngIf="claim.statusCode == '10'" class="text-center">
                                    <span placement="right"
                                        ngbTooltip="This claim has not been submitted yet. Draft Claims have their own list as they are not considered active claims."
                                        class="badge badge-pill badge-success"
                                        style="line-height: 2; background-color:#6c757d; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                        claim.status }}
                                    </span>
                                </td>
                                <td class="text-center" *ngIf="claim.statusCode == '80'"><span placement="right"
                                        [ngbTooltip]="statusCancelled" class="badge badge-pill badge-success"
                                        style="line-height: 2; background-color:#CB4B40; font-size: 85%; width: 148.7px; height: 28.94px;">{{
                                        claim.status }}</span></td>


                                <td class="text-center">{{ claim.sysDateI | date }}</td>
                                <td class="text-center">{{ claim.repairOrderNumber }}</td>
                                <td class="text-center">{{ claim.year }} {{ claim.make }} {{ claim.model }}</td>
                                <td class="text-center">{{ claim.firstName }} {{ claim.lastName }}</td>
                                <td *ngIf="claim.statusCode != '40' && claim.statusCode != '90' && claim.statusCode != '60' && claim.statusCode != '80'"
                                    class="text-center">{{ claim.totalRequested | currency: "$" }}</td>
                                <td *ngIf="claim.statusCode == '40' ||  claim.statusCode == '90' || claim.statusCode == '60' || claim.statusCode == '80'"
                                    data-toggle="modal" data-target="#paymentRequest"
                                    style="cursor: pointer; color: #0000ff;"
                                    (click)="setClaimId(claim?.id); resetPaymentRequest()" class="text-center"><span
                                        placement="top" ngbTooltip="Request Payment">{{ claim.totalAgreed | currency:
                                        "$" }}</span></td>

                                <td class="text-center" [ngClass]="{ 'tx-bold tx-20': !claim.claimNumber }">{{
                                    claim.claimNumber && (claim.statusCode == 40 || claim.statusCode == 60 ||
                                    claim.statusCode == 80 || claim.statusCode == 90) ? claim.claimNumber : "-" }}</td>
                                <td class="text-center" style="min-width: 120px;">
                                    <div class="row mg-r-0 justify-content-around">

                                        <button (click)="continueToStepper(claim)"
                                            class="btn btn-oblong btn-primary btn btn-sm table-button">
                                            Details
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="slim-pageheader pd-0 pd-t-20 align-items-baseline">
                <a (click)="setDraft()" href="/drafts" routerLink="/drafts" class="card-link">View all drafts</a>
                <h4 class="tx-dark mg-t-30">
                    <i class="ion-compose mg-r-10"></i><label>Draft Claims ({{ totalDraftClaims }})</label>
                </h4>
            </div>
            <div *ngIf="draftLoading" class="spinner-border row align-self-center" style="width: 3rem; height: 3rem"
                role="status">
                <span class="sr-only">Loading...</span>
            </div>

            <div *ngIf="!draftLoading" class="row align-items-center justify-content-between">
                <div style="width: 30px;">
                    <i (click)="decreaseCounter()" [ngClass]="{
          'blue-arrow': hasPreviousDraft
        }" ngbTooltip="Previous" class="fa fa-angle-left fa-5x mg-r-5"></i>
                </div>

                <div class="col-12 col-sm-3">
                    <div *ngIf="draftList[counter]" class="card">
                        <div class="card-header align-items-center pd-y-5">
                            <div class="row mg-b-5 pd-x-15 align-items-center justify-content-between">
                                <h5 class="card-title text-wrapping tx-primary tx-medium mg-b-10 mg-t-10 font-weight-bold"
                                    triggers="click" placement="top"
                                    ngbTooltip="{{draftList[counter]?.repairOrderNumber}}">
                                    {{ draftList[counter]?.repairOrderNumber }}
                                </h5>
                                <button class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold table-button"
                                    (click)="continueWithClaim(draftList[counter])">
                                    <i class="fa fa-pencil mg-r-5"></i>Continue
                                </button>
                            </div>
                            <p class="card-subtitle tx-primary text-wrapping" triggers="click" placement="top"
                                ngbTooltip="{{ draftList[counter]?.year }} {{ draftList[counter]?.make }} {{ draftList[counter]?.model }}">
                                {{ draftList[counter]?.year }} {{ draftList[counter]?.make }}
                                {{ draftList[counter]?.model }}
                            </p>
                            <p class="card-subtitle tx-primary text-wrapping" triggers="click" [placement]="top"
                                ngbTooltip=" {{ draftList[counter]?.firstName }} {{ draftList[counter]?.lastName }}">
                                {{ draftList[counter]?.firstName }}
                                {{ draftList[counter]?.lastName }}
                            </p>
                            <div class="row pd-b-10 mg-t-10">
                                <div class="col">
                                    <p class="mg-b-0" [ngClass]="{
                      'tx-info': draftList[counter]?.step >= 1
                    }">
                                        <i class="fa fa-lg mg-r-8" [ngClass]="{
                        'fa-check-circle-o tx-info': draftList[counter]?.step >= 1,
                        'fa-circle-o': draftList[counter]?.step < 1
                      }"></i><strong class="tx-medium">Claim Information</strong>
                                    </p>
                                    <p class="mg-b-0" [ngClass]="{
                      'tx-info': draftList[counter]?.step >= 2
                    }">
                                        <i class="fa fa-lg mg-r-8" [ngClass]="{
                        'fa-check-circle-o tx-info': draftList[counter]?.step >= 2,
                        'fa-circle-o': draftList[counter]?.step < 2
                      }"></i><strong class="tx-medium">Job Summary</strong>
                                    </p>
                                </div>
                                <div class="col">
                                    <p class="mg-b-0" [ngClass]="{
                      'tx-info': draftList[counter]?.step >= 3
                    }">
                                        <i class="fa fa-lg mg-r-8" [ngClass]="{
                        'fa-check-circle-o tx-info': draftList[counter]?.step >= 3,
                        'fa-circle-o': draftList[counter]?.step < 3
                      }"></i><strong class="tx-medium">Claim Details</strong>
                                    </p>
                                    <div class="row justify-content-end">
                                        <span (click)="setClaimDelete(draftList[counter]?.id)" data-toggle="modal"
                                            attr.data-target="#confirmActionModal{{indexNum}}"
                                            id="deleteClaimButtonFromClaimInfo" style="cursor: pointer;"
                                            class="icon icon--trash_48_outline pd-x-5 col-xl-2 col-md-5 mg-r-10">
                                        </span>
                                    </div>
                                    <app-confirm-action [header]="header" [message]="message"
                                        [buttonMessage]="buttonMessage" [indexNum]="indexNum"
                                        (confirmActionEvent)="deleteClaim($event)"></app-confirm-action>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div *ngIf="draftList[counter+1]" class="card">
                        <div class="card-header align-items-center pd-y-5">
                            <div class="row mg-b-5 pd-x-15 align-items-center justify-content-between">
                                <h5
                                    class="card-title tx-primary text-wrapping tx-medium mg-b-10 mg-t-10 font-weight-bold">
                                    {{ draftList[counter + 1]?.repairOrderNumber }}
                                </h5>
                                <button class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold table-button"
                                    (click)="continueWithClaim(draftList[counter + 1])">
                                    <i class="fa fa-pencil mg-r-5"></i>Continue
                                </button>
                            </div>
                            <p class="card-subtitle tx-primary text-wrapping" triggers="click" placement="top"
                                ngbTooltip="{{ draftList[counter + 1]?.year }} {{ draftList[counter + 1]?.make }} {{ draftList[counter + 1]?.model }}">
                                {{ draftList[counter + 1]?.year }} {{ draftList[counter + 1]?.make }}
                                {{ draftList[counter + 1]?.model }}
                            </p>
                            <p class="card-subtitle tx-primary text-wrapping" triggers="click" placement="top"
                                ngbTooltip="{{ draftList[counter + 1]?.firstName }} {{ draftList[counter + 1]?.lastName }}">
                                {{ draftList[counter + 1]?.firstName }}
                                {{ draftList[counter + 1]?.lastName }}
                            </p>
                            <div class="row pd-b-10 mg-t-10">
                                <div class="col">
                                    <p class="mg-b-0" [ngClass]="{
                      'tx-info': draftList[counter + 1]?.step >= 1
                    }">
                                        <i class="fa fa-lg mg-r-8" [ngClass]="{
                        'fa-check-circle-o tx-info': draftList[counter + 1]?.step >= 1,
                        'fa-circle-o': draftList[counter + 1]?.step < 1
                      }"></i><strong class="tx-medium">Claim Information</strong>
                                    </p>
                                    <p class="mg-b-0" [ngClass]="{
                      'tx-info': draftList[counter + 1]?.step >= 2
                    }">
                                        <i class="fa fa-lg mg-r-8" [ngClass]="{
                        'fa-check-circle-o tx-info': draftList[counter + 1]?.step >= 2,
                        'fa-circle-o': draftList[counter + 1]?.step < 2
                      }"></i><strong class="tx-medium">Job Summary</strong>
                                    </p>
                                </div>
                                <div class="col">
                                    <p class="mg-b-0" [ngClass]="{
                      'tx-info': draftList[counter + 1]?.step >= 3
                    }">
                                        <i class="fa fa-lg mg-r-8" [ngClass]="{
                        'fa-check-circle-o tx-info': draftList[counter + 1]?.step >= 3,
                        'fa-circle-o': draftList[counter + 1]?.step < 3
                      }"></i><strong class="tx-medium">Claim Details</strong>
                                    </p>
                                    <div class="row justify-content-end">
                                        <span (click)="setClaimDelete(draftList[counter + 1]?.id)" data-toggle="modal"
                                            attr.data-target="#confirmActionModal{{indexNum}}"
                                            id="deleteClaimButtonFromClaimInfo" style="cursor: pointer;"
                                            class="icon icon--trash_48_outline pd-x-5 col-xl-2 col-md-5 mg-r-10">
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div *ngIf="draftList[counter + 2]" class="card">
                        <div class="card-header align-items-center pd-y-5">
                            <div class="row mg-b-5 pd-x-15 align-items-center justify-content-between">
                                <h5
                                    class="card-title tx-primary text-wrapping tx-medium mg-b-10 mg-t-10 font-weight-bold">
                                    {{ draftList[counter + 2]?.repairOrderNumber }}
                                </h5>
                                <button class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold table-button"
                                    (click)="continueWithClaim(draftList[counter + 2])">
                                    <i class="fa fa-pencil mg-r-5"></i>Continue
                                </button>
                            </div>
                            <p class="card-subtitle tx-primary text-wrapping" triggers="click" placement="top"
                                ngbTooltip="{{ draftList[counter + 2]?.year }} {{ draftList[counter + 2]?.make }} {{ draftList[counter + 2]?.model }}">
                                {{ draftList[counter + 2]?.year }} {{ draftList[counter + 2]?.make }}
                                {{ draftList[counter + 2]?.model }}
                            </p>
                            <p class="card-subtitle tx-primary text-wrapping" triggers="click" placement="top"
                                ngbTooltip="{{ draftList[counter + 2]?.firstName }} {{ draftList[counter + 2]?.lastName }}">
                                {{ draftList[counter + 2]?.firstName }}
                                {{ draftList[counter + 2]?.lastName }}
                            </p>
                            <div class="row pd-b-10 mg-t-10">
                                <div class="col">
                                    <p class="mg-b-0" [ngClass]="{
                      'tx-info': draftList[counter + 2]?.step >= 1
                    }">
                                        <i class="fa fa-lg mg-r-8" [ngClass]="{
                        'fa-check-circle-o tx-info': draftList[counter + 2]?.step >= 1,
                        'fa-circle-o': draftList[counter + 2]?.step < 1
                      }"></i><strong class="tx-medium">Claim Information</strong>
                                    </p>
                                    <p class="mg-b-0" [ngClass]="{
                      'tx-info': draftList[counter + 2]?.step >= 2
                    }">
                                        <i class="fa fa-lg mg-r-8" [ngClass]="{
                        'fa-check-circle-o tx-info': draftList[counter + 2]?.step >= 2,
                        'fa-circle-o': draftList[counter + 2]?.step < 2
                      }"></i><strong class="tx-medium">Job Summary</strong>
                                    </p>
                                </div>
                                <div class="col">
                                    <p class="mg-b-0" [ngClass]="{
                      'tx-info': draftList[counter + 2]?.step >= 3
                    }">
                                        <i class="fa fa-lg mg-r-8" [ngClass]="{
                        'fa-check-circle-o tx-info': draftList[counter + 2]?.step >= 3,
                        'fa-circle-o': draftList[counter + 2]?.step < 3
                      }"></i><strong class="tx-medium">Claim Details</strong>
                                    </p>
                                    <div class="row justify-content-end">
                                        <span (click)="setClaimDelete(draftList[counter + 2]?.id)" data-toggle="modal"
                                            attr.data-target="#confirmActionModal{{indexNum}}"
                                            id="deleteClaimButtonFromClaimInfo" style="cursor: pointer;"
                                            class="icon icon--trash_48_outline pd-x-5 col-xl-2 col-md-5 mg-r-10">
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 30px;">
                    <i (click)="increaseCounter()" [ngClass]="{
          'blue-arrow': hasNextDraft
        }" ngbTooltip="Next" class="fa fa-angle-right fa-5x mg-r-5"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="paymentRequest" class="modal">
    <div class="modal-dialog modal-lg modal-width-payment" role="document">
        <form fxLayout="column" [formGroup]="paymentRequestForm" (ngSubmit)="submitPaymentRequestForm()">
            <div class="modal-content tx-size-sm">
                <div class="modal-header pd-x-20 pd-b-30">
                    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                        Request payment <i class="fa mg-l-5 fa-info-circle zurich-blue"
                            style="font-size: 1.35em; cursor: pointer;" placement="auto" triggers="click"
                            [ngbTooltip]="requestPayment"></i>
                    </h6>
                    <button type="button" class="close" aria-label="Close" id="paymentRequestModalButton"
                        data-dismiss="modal" (click)="paymentRequestClose()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="row">
                    <div class="col-md">
                        <div class="card">
                            <div class="card-body">
                                <div class="row justify-content-around">
                                    <div>
                                        <h4 class="tx-primary">Attach completed RO to request payment</h4>
                                    </div>
                                </div>

                                <div class="row justify-content-center pd-b-20">
                                    <ngx-dropzone (change)="onSelectFiles($event)">

                                        <ngx-dropzone-label>
                                            <i placement="top" ngbTooltip="Add Attachment"
                                                class="fa fa-2x far tx-primary fa-upload mg-r-10"></i>
                                            <br>
                                            Drag and drop Files Here to Upload <br>
                                            or Click to Select Files
                                        </ngx-dropzone-label>

                                        <ngx-dropzone-preview *ngFor="let f of files; let i = index" [removable]="true"
                                            (removed)="onRemove(i)">

                                            <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>

                                        </ngx-dropzone-preview>

                                    </ngx-dropzone>
                                </div>
                                <div class="row mg-b-20 justify-content-around">

                                    <textarea class="form-control col-10" rows="2" placeholder="Type here..."
                                        [formControlName]="'paymentRequestText'" [ngClass]="{
                    'is-invalid': paymentRequestSubmitted && fPaymentRequest.paymentRequestText.errors
                  }"></textarea>


                                </div>
                                <div class="row justify-content-end">
                                    <div class="pd-l-0 pd-r-0 justify-content-end">
                                        <button type="submit" class="btn btn-oblong btn-primary btn">
                                            Submit
                                        </button>
                                        <div *ngIf="paymentRequesting" class="spinner-border "
                                            style="width: 2rem; height: 2rem; vertical-align: middle;" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>