import { environment } from "src/environments/environment";

const OKTA_TESTING_DISABLEHTTPSCHECK = undefined;
export default {
  oidc: {
    clientId: `${environment.CLIENT_ID}`,
    issuer: `${environment.ISSUER}`,
    redirectUri: '/login/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: `${OKTA_TESTING_DISABLEHTTPSCHECK}`,
    },
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};
